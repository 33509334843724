import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import logger from "./logs/logger";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/passwordStyles.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [savePasswordMessage, setSavePasswordMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const tokenFromUrl = getUrlParameter("token");
    if (tokenFromUrl) {
      handleSetPassword(tokenFromUrl);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() !== "") {
      setEmailError("");
      handleSetPassword();
    } else {
      setEmailError("Email is required");
    }
  };

  const handleSetPassword = async (tokenFromUrl) => {
    const emailValue = email.trim();

    if (emailValue === "") {
      alert("Email is missing. Unable to set the password.");
      return;
    } else {
      setSavePasswordMessage("");
    }

    const requestBody = {
      to: emailValue,
    };
    try {
      const response = await fetch(`${API_BASE_URL}/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.code === 200) {
        setSavePasswordMessage(data);
        setFormSubmitted(false);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setSavePasswordMessage({
          description: data.description || "Failed to set password",
        });
        setFormSubmitted(false);
      }
    } catch (error) {
      logger.error("Error:", error);
    }
  };

  const getUrlParameter = (name) => {
    // Escape square brackets in the parameter name
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    logger.info("NAME", name);
    // Construct a regular expression to match the parameter in the URL query string
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    logger.info("REGEX", regex);
    // Execute the regular expression on the URL query string
    const results = regex.exec(window.location.search);
    logger.info("RESULTS", results);
    // Return the decoded parameter value if found, otherwise an empty string
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  return (
    <div className="full-width-container">
      <div className="at-gems"> AT-Gems </div>
      <div className="password-container">
        <div className="password-form-container">
          <h1 className="mb-4 forgot-password">Forgot Password</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start">
              <label htmlFor="email" className="email-label">
                Enter your Email ID:
              </label>
              <input type="email" className="email-input" id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value); setEmailError("");}} placeholder="Email Address"/>
              <div className="text-danger">{emailError}</div>
            </div>
            <button type="submit" className="submit-button" disabled={formSubmitted}>Submit</button>
          </form>
          {savePasswordMessage && (
            <div
              className={`message ${
                savePasswordMessage.code === 200 ? "success" : "error"
              }`}
              style={{ color: savePasswordMessage.code === 200 ? "green" : "red" }}
            >
              <p>{savePasswordMessage.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
