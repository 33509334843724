import React, { useState, useEffect } from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_BASE_URL } from "./constant/apiConstants";
import logger from "./logs/logger";
import { UserHeaders } from "./constant/localStorageConstants";
import { getAuthHeaders } from './authUtils';
import "./styles/Chart.css";

const COLORS = ["#242D67", "#D3BDFF", "#A0E4FF", "#cc66ff"];

const CustomPieChart = () => {
  const [remainingLeaves, setRemainingLeaves] = useState({});
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

  const fetchRemainingLeaves = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/remainingleaves/${UserHeaders.USERID.value}?country=${UserHeaders.COUNTRY.value}`,{
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
         },
      });
      if (!response.ok) {


/*
        logger.warn("Remaining leaves response not OK:", response);
 */
        return;
      }

      const data = await response.json();
/*
      logger.info("Received remaining leaves data:", data);
 */


      if (data.message && typeof data.message === "object") {
        setRemainingLeaves(data.message);
      } else {
/*
        logger.error("Remaining leaves data is not a valid object:", data);
 */
      }
    } catch (error) {
/*
      logger.error("Error fetching remaining leaves:", error);
 */
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRemainingLeaves();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  const leavesDataForPieChart = Object.entries(remainingLeaves).filter(([, value]) => value !== 0);
  const totalParts = leavesDataForPieChart.length;

  let pieChartData = [];
  if (totalParts < 4) {
    pieChartData = leavesDataForPieChart.map(([key]) => ({ name: key, value: 1 }));
  } else {
    const [fourthPart] = leavesDataForPieChart.splice(3, 1);
    const totalValue = leavesDataForPieChart.reduce((acc, [, value]) => acc + value, 0) + fourthPart[1];
    const fourthPartValue = (fourthPart[1] / totalValue) * 3;

    pieChartData = [
      ...leavesDataForPieChart.map(([key]) => ({ name: key, value: 1 })),
      { name: fourthPart[0], value: fourthPartValue },
    ];
 }

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="recharts-default-legend">
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`} className="custom-legend-item" >
            <svg className="custom-legend-icon">
              <circle cx="4.5" cy="4.5" r="4.5" fill={entry.color} />
            </svg>
            {entry.value === 0 ? (
              <span className="custom-legend-value inactive">{entry.value}</span>
            ) : (
              entry.value
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="custom-pie-chart-container">
      <div className="custom-pie-chart-card">
        <div className="custom-card-body">
          <h5 className="custom-card-title">Remaining Leaves</h5>
          <PieChart className="custom-pie-chart" width={450} height={400}>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              startAngle={-30}
              endAngle={330}
              outerRadius={110}
              fill="#2f498f"
              labelLine={false}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                const RADIAN = Math.PI / 180;
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                return (
                  <text
                    x={x}
                    y={y}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="#ffffff"
                    fontSize="15px"
                    fontWeight="bold"
                  >
                    {remainingLeaves[pieChartData[index].name]}
                  </text>
                );
              }}
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>

            <Tooltip
              formatter={(value, name) => [remainingLeaves[name], name]}
              contentStyle={{
                fontFamily: "Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                background: "linear-gradient(45deg, #FF6347, #66CDAA)",
                border: "none",
                borderRadius: "10px",
                textAlign: "center",
              }}
              itemStyle={{ color: "#fff" }}
            />

            <Legend
              content={renderLegend}
              iconSize={10}
              iconType="circle"
              layout="vertical"
              align="right"
              verticalAlign="middle"
              wrapperStyle={{ paddingLeft: "20px" }}
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default CustomPieChart;
