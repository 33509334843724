import React, { useState, useEffect } from "react";
import "./styles/applicationform.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';

const WFHApplication = () => {
  const [wfhDate, setwfhDate] = useState("");
  const [reason, setReason] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const navigate = useNavigate();
  const [applyResponse, setApplyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accessToken, setAccessToken] = useState(
    UserHeaders.ACCESSTOKEN.value
  );
 const [validationErrors, setValidationErrors] = useState({
    leaveType: "",
    wfhDate: "",
    reason: "",
  });
  const [eligibilityError, setEligibilityError] = useState("");
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (wfhDate) {
      setNumberOfDays(1);
    }
  }, [wfhDate]);

  const validateForm = () => {
    const errors = {
      wfhDate: wfhDate ? "" : "wfh Date is required",
      reason: reason ? "" : "Reason is required",
    };
    setValidationErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = async (e) => {
    await handleExpiredAccessToken(navigate, setAccessToken);
    if (isSubmitting) return;

    setIsSubmitting(true);

    e.preventDefault();

    if (!validateForm()) {
      // Validation failed, don't proceed with submission
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/apply-wfh/${UserHeaders.USERID.value}`,
        {
          method: "POST",
          headers: {
            ...getAuthHeaders(accessToken),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            wfhDate: wfhDate,
            no_of_days: numberOfDays,
            reason: reason,
          }),
        }
      );

      if (response.ok) {
        setEligibilityError('');
        const eligibilityResponse = await response.json();
        if(eligibilityResponse.code === 422){
          logger.info("Eligibity status : ", eligibilityResponse);
          setEligibilityError(`Not eligible. Your last applied Date: ${eligibilityResponse.message} You can only take WFH once in 25 days`);
        } else {
            logger.info("WFHApproval application submitted successfully!");
            logger.info("Eligibity status : ", eligibilityResponse);
            setApplyResponse({ description: eligibilityResponse.description });
            setwfhDate("");
            setReason("");
            setNumberOfDays(0);
            setTimeout(() => navigate("/home/WFHRequest"), 3000); // Redirect after 3 seconds
        }
      } else {
        setEligibilityError('');
        const errorResponse = await response.json();
        setApplyResponse({ description: errorResponse.message });

        logger.error(
          "Error submitting WFHApproval application:",
          response.status,
          errorResponse
        );
      }
    } catch (error) {
      logger.error("An error occurred during submission:", error.message);
    }
    setIsSubmitting(false);
  };
  const handleCancel = () => {
    navigate("/home");
  };
  return (
    <div className="application-container">
      <div className="form-container">
        <form className="wfh-application-form" onSubmit={handleSubmit} method="POST">
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                WFH Date:
                <input type="date" value={wfhDate} min={getCurrentDate()} onChange={(e) => setwfhDate(e.target.value)} className={wfhDate ? "" : "input-placeholder"}/>
              </label>
              <div> <p className="error-message">{validationErrors.fromDate}</p></div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                Number of Days:
                <input type="number" value={numberOfDays} readOnly className={numberOfDays === 0 ? "input-placeholder" : ""}/>
              </label>
            </div>
          </div>
          &nbsp;
          &nbsp;
          &nbsp;
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                Reason for WorkFromHome:
                <textarea value={reason} onChange={(e) => setReason(e.target.value)} className={reason ? "" : "input-placeholder"}/>
              </label>
             <div> <p className="error-message"> {validationErrors.reason}</p> </div>
             <div  className="error-message">{eligibilityError && ( <p>{eligibilityError}</p> )} </div>
             <div className="success-message">{applyResponse && (<p>{applyResponse.description}</p>)}</div>
            </div>
          </div>
          &nbsp;
          <div className="form-row">
            <button type="button" onClick={handleSubmit} disabled={isSubmitting} className="submit-button">Submit</button>
            <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WFHApplication;