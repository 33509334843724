import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import "./styles/ProfilePage.css";
import TopNavBar from './TopNavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Calendar from './Calendar';
import DobAnniversary from './DobAnniversary';
import CustomPieChart from './CustomPieChart';
import HolidayProgress from './HolidayProgress';
import { API_BASE_URL } from './constant/apiConstants';
import { getAuthHeaders } from './authUtils';
import { UserHeaders } from './constant/localStorageConstants';
import logger from './logs/logger';

const UserHomePage = () => {
  const [isDateReasonsAvailable, setIsDateReasonsAvailable] = useState(false);
  const [hasBirthdaysToday, setHasBirthdaysToday] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchHolidayData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/holidays`, {
        method: 'POST',
          headers: {
            ...getAuthHeaders(UserHeaders.ACCESSTOKEN.value),
            'X-Country': UserHeaders.COUNTRY.value,
            'X-UserRole': parseInt(UserHeaders.USERROLE.value, 10),
            'X-UserId': UserHeaders.USERID.value,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.code === 200 && Array.isArray(data.message)) {
            setHolidayData(data.message);
          } else {
            logger.error('Unexpected response structure:', data);
            setHolidayData([]);
          }
        } else {
          logger.error('Failed to fetch holidays');
          setHolidayData([]);
        }
      } catch (error) {
        logger.error('Error fetching holiday data:', error);
        setHolidayData([]);
      }
    };

    fetchHolidayData();
  }, []);

  const handleDateReasonsAvailabilityChange = (availability) => {
    setIsDateReasonsAvailable(availability);
  };

  const handleHasBirthdaysTodayChange = (hasBirthdays) => {
    setHasBirthdaysToday(hasBirthdays);
  };

  const isHomePage = location.pathname === '/home';

  return (
    <div className="user-home-container">
      <TopNavBar />
      <div className="user-home-page-container">
        {isHomePage && (
          <>
            <div className="calendar-container">
              <Calendar onDateReasonsAvailable={handleDateReasonsAvailabilityChange} />
              <DobAnniversary onHasBirthdaysTodayChange={handleHasBirthdaysTodayChange} />
            </div>
            {(UserHeaders.EMPLOYEESTATUS.value === 2 ||
              (UserHeaders.EMPLOYEESTATUS.value !== 1 &&
               UserHeaders.COUNTRY.value !== "IN" &&
               UserHeaders.USERROLE.value !== 2)) && (
             <div className="chart-container">
               <CustomPieChart />
               {(isDateReasonsAvailable || hasBirthdaysToday) && (
                 <HolidayProgress
                   isDateReasonsAvailable={isDateReasonsAvailable}
                   hasBirthdaysToday={hasBirthdaysToday}
                   holidayData={holidayData}
                 />
               )}
             </div>
            )}
            {((!isDateReasonsAvailable && !hasBirthdaysToday) || UserHeaders.EMPLOYEESTATUS.value === 1) && (
              <div className="holiday-progress-container">
                <HolidayProgress
                  isDateReasonsAvailable={isDateReasonsAvailable}
                  hasBirthdaysToday={hasBirthdaysToday}
                  holidayData={holidayData}
                />
              </div>
            )}
          </>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default UserHomePage;
