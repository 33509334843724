import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logger from './logs/logger';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export const Login = (props) => {
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const [userTypes, setUserTypes] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [loginResponse, setLoginResponse] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

useEffect(() => {
  const fetchDropdownOptions = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/metadata/usertypes`, {
        method: 'POST',
      });

      if (response.ok) { 
        const data = await response.json();
        const optionsArray = Object.keys(data).map((key) => ({
          name: data[key].name,
          description: data[key].description,
          enumValue: key,
          id: data[key].id,
        }));
        setUserTypes(optionsArray);
      } else {
        logger.error('Failed to fetch dropdown options');
      }
    } catch (error) {
      logger.error('An error occurred while fetching dropdown options:', error);
    }
  };

  fetchDropdownOptions();
}, []);

const handleRedirectToRegister = () => {
  navigate("/register");
}

    const handleSubmit = (e) => {
        e.preventDefault();
/*
        logger.info(username, pass);
 */

        if (!username || !pass || !selectedUserType) {
            setErrorMessage("Please fill in all fields, including selecting a user type.");
            return;
        }
        fetch(`${API_BASE_URL}/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: pass,
                    userType: selectedUserType
                })
            })
            .then(async (response) => {
                const userRole = response.headers.get('X-UserRole');
                const userName = response.headers.get('X-UserName');
                const userId = response.headers.get('X-userId');
                const departmentid=response.headers.get('X-Departmentid');
                const userGender = response.headers.get('X-Gender');
                const accessToken = response.headers.get('Authorization');
                const refreshToken = response.headers.get('Refresh-Token');
                const employeeStatus = response.headers.get('X-EmployeeStatus');
                const country = response.headers.get('X-Country');

             /*    logger.info("test logger");
                logger.info('X-UserRole :', userRole);
                logger.info('X-UserName :', userName);
                logger.info('X-userId :', userId);
                logger.info('accessToken :', accessToken);
                logger.info('RefreshToken :', refreshToken);
                logger.info('X-Departmentid :', departmentid);
                logger.info('X-Gender :', userGender);
                logger.info('X-EmployeeStatus :', employeeStatus);
 */
                if(selectedUserType == "EMPLOYEE") {
                    localStorage.setItem('X-UserRole', 5);
                } else {
                    localStorage.setItem('X-UserRole', userRole);
                }
                localStorage.setItem('X-UserName', userName);
                localStorage.setItem('X-userId', userId);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('X-Departmentid', departmentid);
                localStorage.setItem('X-Gender', userGender);
                localStorage.setItem('X-EmployeeStatus', employeeStatus);
                localStorage.setItem('X-Country', country);

                const data = await response.json();
                setLoginResponse(data);
                setModalIsOpen(true);

                if (data.message !== undefined) {
                    localStorage.setItem('isManager', data.message);
                }

                if (data.code === 200) {
                    setIsLoggedIn(true);
                    navigate('/home');
                    const redirectUrl = localStorage.getItem('redirectUrl');
                     if (redirectUrl) {
                         localStorage.removeItem('redirectUrl');
                         navigate(redirectUrl);
                     } else {
                         navigate('/home');
                     }
                } else {
                    setErrorMessage(data.description);
                }
            })
            .catch((err) => {
                logger.error('Error:', err);
            });
    };

    return (
        <div className="login-container">
            <div className="login-image-container"></div>
            <div className="login-form-container">
                <h2>Log In</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Employee Name</label>
                    <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        placeholder="Please enter your Employee Name"
                        id="username"
                        name="username"
                    />

                    <label htmlFor="password">Password</label>
                    <input
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="**********"
                        id="password"
                        name="password"
                    />
                    <span className="login-password-toggle-icon">
                        <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </span>
                    <label htmlFor="userType">User Type</label>
                    <select
                        id="userType"
                        value={selectedUserType}
                        onChange={(e) => setSelectedUserType(e.target.value)}
                    >
                        <option value="">Select a User Type</option>
                        {userTypes.map((option) => (
                            <option key={option.id} value={option.enumValue}>
                                {option.name}
                            </option>
                        ))}
                    </select>

                    <button type="submit" className="mt-3">Log In</button>
                </form>

                <div style={{ marginTop: '1rem' }}>
                    <Link to="/forgotpassword">Forgot Password?</Link>
                </div>

                {errorMessage && (
                    <div className="login-response">
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;