import React from 'react';
const SalaryDetails = () => {
  return (
   <div className="salary-details-container">

          </div>
  );
}

export default SalaryDetails;
