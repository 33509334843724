import logger from "./logs/logger";
import { jwtDecode } from "jwt-decode";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";

export const handleExpiredAccessToken = async (navigate, setAccessToken) => {
  try {
    if (isAccessTokenExpired()) {
      const { accessToken,refreshToken } = getTokensFromLocalStorage();
      const newAccessToken = await handleTokenRefresh(
        refreshToken,
        navigate,
        setAccessToken
      );
      if (newAccessToken) {
        saveTokensToLocalStorage(newAccessToken, refreshToken);
        setAccessToken(newAccessToken);
      } else {
        clearTokensFromLocalStorage();
        navigate("/login");
      }
    }
  } catch (error) {
    logger.error("Error handling expired access token:", error);
    clearTokensFromLocalStorage();
    navigate("/login");
  }
};

export const getTokensFromLocalStorage = () => {
  const accessToken = UserHeaders.ACCESSTOKEN.value;
  const refreshToken = UserHeaders.REFRESHTOKEN.value;
  return { accessToken, refreshToken };
};

export const fetchDataWithTokenRefresh = async (
  accessToken,
  refreshToken,
  navigate,
  setAccessToken,
  fetchUserDetails,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    logger.info("Before token expiration check");
    if (await isAccessTokenExpired()) {
      logger.info("Before token refresh");
      const newAccessToken = await handleTokenRefresh(
        refreshToken,
        navigate,
        setAccessToken
      );
      logger.info("After token refresh, new access token:", newAccessToken);
      if (newAccessToken) {
        await fetchUserDetails();
      }
    } else {
      await fetchUserDetails();
    }
  } catch (error) {
    logger.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

export const handleTokenRefresh = async (
  refreshToken,
  navigate,
  setAccessToken
) => {
  try {
    logger.info("Before token refresh request");
    const refreshTokenResponse = await fetch(`${API_BASE_URL}/refresh-token`, {
      method: "POST",
      headers: {
        "Refresh-Token": refreshToken,
      },
    });

    logger.info("After token refresh request");

    if (refreshTokenResponse.ok) {
      const newAccessToken = refreshTokenResponse.headers.get("Authorization");
      saveTokensToLocalStorage(newAccessToken, refreshToken);
      setAccessToken(newAccessToken);
      return newAccessToken;
    } else {
      clearTokensFromLocalStorage();
      navigate("/login");
      return null;
    }
  } catch (error) {
    clearTokensFromLocalStorage();
    navigate("/login");
    return null;
  }
};

export const saveTokensToLocalStorage = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  }

  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export const clearTokensFromLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("X-userId");
  localStorage.removeItem("X-UserName");
  localStorage.removeItem("X-UserRole");
  localStorage.removeItem("X-Gender");
  localStorage.removeItem("X-EmployeeStatus");
};

export const getAuthHeaders = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export function isAccessTokenExpired() {
  const accessToken = UserHeaders.ACCESSTOKEN.value;

  if (!accessToken) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(accessToken);
    const expirationTime = new Date(decodedToken.exp * 1000);
   // Check if the token is expired
    const isExpired = decodedToken.exp * 1000 < Date.now();
    return isExpired;
  } catch (error) {
    return true;
  }
}
