import React, { useState } from "react";
import { BrowserRouter as Router} from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import './styles/App.css';


function App() {

return (
  <Router>
      <div className="App">
        <AppRoutes />
      </div>
    </Router>
  );

}

export default App;
