import React, { useState, useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import './styles/AddEmployee.css';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import {
  handleExpiredAccessToken
} from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { MultiSelect } from "react-multi-select-component";


const AddEmployee = () => {

  const [responseMessage, setResponseMessage] = useState('');
  const [responseCode,setResponseCode]=useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [first_name, setFirstname] = useState('');
  const [first_name_error, setFirstNameError] = useState('');
  const [last_name, setLastname] = useState('');
  const [last_name_error, setLastNameError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [regionError, setRegionError] = useState('');
  const [employee_id, setEmployeeId] = useState('');
  const [employeeIdError, setEmployeeIdError] = useState('');
  const [username, setUsername] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [manager, setManager] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');
  const [managerError, setManagerError] = useState('');
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState('');
  const [jobPositionError, setJobPositionError] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [genderError,setGenderError] = useState('');
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [personalemail, setPersonalEmail] = useState('');
  const [personalemailError, setPersonalEmailError] = useState('');
  const [currentPostalCode, setcurrentPostalCode] = useState("");
  const [currentPostalCodeError, setcurrentPostalCodeError] = useState("");
  const [currentState, setcurrentState] = useState("");
  const [currentStateError, setcurrentStateError] = useState("");
  const [date_of_birth, setDateOfBirth] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [alternatephoneNumber, setAlternatePhoneNumber] = useState('');
  const [alternatephoneError] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [permanentAddressError, setPermanentAddressError] = useState('');
  const [bloodGroup, setBloodGroup] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
  const [bloodGroupError, setBloodGroupError] = useState('');
  const [familyContact, setFamilyContact] = useState('');
  const [familyContactError, setFamilyContactError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [passport, setPassport] = useState('');
  const [passportError, setPassportError] = useState('');
  const [pancard, setPancard] = useState('');
  const [pancardError, setPancardError] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [aadharError, setAadharError] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [joiningDateError, setJoiningDateError] = useState('');
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState('');
  const [employeeStatusError, setEmployeeStatusError] = useState('');
  const [employeeStatus, setEmployeeStatus] =useState([]);
  const [permanentPostalCode, setpermanentPostalCode] = useState("");
  const [permanentPostalCodeError, setpermanentPostalCodeError] = useState("");
  const [permanentState, setpermanentState] = useState("");
  const [permanentStateError, setpermanentStateError] = useState("");
  const [permanentCountry, setpermanentCountry] = useState("");
  const [permanentCountryError, setpermanentCountryError] = useState("");
  const [familyRelation, setfamilyRelation] = useState("");
  const [familyRelationError, setfamilyRelationError] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine2Error, setaddressLine2Error] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [options, setOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [workLocationError,setWorkLocationError]=useState('');
  const [familyRelationName, setfamilyRelationName] = useState("");
  const [familyRelationNameError, setfamilyRelationNameError] = useState("");
  const [familyRelationAddress, setfamilyRelationAddress] = useState("");
  const [familyRelationAddressError, setfamilyRelationAddressError] = useState("");
  const [maritalStatus, setmaritalStatus] = useState([]);
  const [maritalStatusError, setmaritalStatusError] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');


  const useAdminRole = () => {
    const userRole = UserHeaders.USERROLE.value;
    return userRole === 1 || userRole == 2;
  };

  const isHRRole = (role) => {
    return role === 'HR';
  };

  const navigate = useNavigate();
  const isAdmin = useAdminRole();

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/usertypes`, {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const optionsArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setUserTypes(optionsArray);
        } else {
          logger.error('Failed to fetch dropdown options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching dropdown options:', error);
      }
    };
    fetchDropdownOptions();
  }, [accessToken]);

  useEffect(() => {
      const fetchMaritalStatusOptions = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/metadata/maritalStatus`);
          if (response.ok) {
            const data = await response.json();
            const maritalStatusArray = Object.keys(data).map((key) => ({
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            }));
            setmaritalStatus(maritalStatusArray);
          } else {
            logger.error('Failed to fetch Marital Status options');
          }
        } catch (error) {
          logger.error('An error occurred while fetching Marital Status options:', error);
        }
      };
      fetchMaritalStatusOptions();
    }, [accessToken]);

  useEffect(() => {
    const fetchGenderOptions = async () => {
      try {
       const response = await fetch(`${API_BASE_URL}/metadata/genders`,{
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           ...getAuthHeaders(accessToken),
         },
       });
        if (response.ok) {
          const data = await response.json();
          const genderArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setGender(genderArray);
        } else {
          logger.error('Failed to fetch gender options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching gender options:', error);
      }
    };
    fetchGenderOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchBloodGroupOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/blood-groups`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const bloodGroupArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setBloodGroup(bloodGroupArray);
        } else {
          logger.error('Failed to fetch blood group options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching blood group options:', error);
      }
    };
    fetchBloodGroupOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchEmployeeStatusOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/employeestatus`,{
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const employeeStatusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setEmployeeStatus(employeeStatusArray);
        } else {
          logger.error('Failed to fetch employee status options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching employee status options:', error);
      }
    };
    fetchEmployeeStatusOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchDepartmentOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/departments`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const departmentArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setDepartment(departmentArray);
        } else {
          logger.error('Failed to fetch department options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching department options:', error);
      }
    };
    fetchDepartmentOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/countrystatus`,{
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
             ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setCountries(countryArray);
          const countriesForMultiSelect = countryArray.map((country) => ({
            label: country.name,
            value: country.id.toString(),
          }));
          setOptions([{ label: 'Select All', value: 'all' }, ...countriesForMultiSelect]);
        } else {
          logger.error('Failed to fetch country options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, [accessToken]);

  const fetchJobPositions = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/metadata/jobpositions?department=${department}`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(accessToken),
        },
      });
      if (response.ok) {
        const data = await response.json();
        const jobPositionArray = Object.keys(data.message).map((key) => {
          return {
            name: data.message[key].name,
            description: data.message[key].description,
            enumValue: key,
            department: data.message[key].department,
            id: data.message[key].id,
          };
        });
        setJobPositions(jobPositionArray);
      } else {
        logger.error('Failed to fetch job positions');
      }
    } catch (error) {
      logger.error('An error occurred while fetching job positions:', error);
    }
  };

  const fetchManager = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);

      // Fetch users in the department
      const usersInDepartmentResponse = await fetch(`${API_BASE_URL}/users-in-department?department=${department}`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(accessToken),
        },
      });
      logger.info('USER IN DEPARTMENT RESPONSE', usersInDepartmentResponse);
      const usersInDepartmentMessage = await usersInDepartmentResponse.json();
      const usersInDepartmentData = usersInDepartmentMessage.message;
      logger.info('USER IN DEPARTMENT DATA', usersInDepartmentData);

      if (Array.isArray(usersInDepartmentData)) {
        setManager(usersInDepartmentData);
        setManagerError('');
      } else if (typeof usersInDepartmentData === 'object') {
        // Convert the object to an array of users
        const usersArray = Object.keys(usersInDepartmentData).map((username) => ({
          id: usersInDepartmentData[username],
          username: username,
        }));

        setManager(usersArray);
        setManagerError('');
      } else {
        logger.error('Invalid data format received:', usersInDepartmentData);
        setManagerError('Invalid data format received');
      }
    } catch (error) {
      logger.error('An error occurred while fetching users in the department:', error);
      setManagerError('An error occurred while fetching users in the department');
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    logger.info('SELECTED DEPARTMENT', selectedDepartment);
    setSelectedDepartment(selectedDepartment);
    setSelectedJobPosition(null);
    fetchManager(selectedDepartment);
    fetchJobPositions(selectedDepartment);
  };

  const handleJobPositionChange = (e) => {
    const value = e.target.value;
    setSelectedJobPosition(value);
  };

  const handleBloodGroupChange = (e) => {
    const selectedBloodGroup = e.target.value;
    logger.info('SELECTED BLOOD GROUP', selectedBloodGroup);
    setSelectedBloodGroup(selectedBloodGroup);
  };

  const handleMaritalStatusChange = (e) => {
    const selectedMaritalStatus = e.target.value;
    logger.info('SELECTED Marital Status', selectedMaritalStatus);
    setSelectedMaritalStatus(selectedMaritalStatus);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    logger.info('SELECTED Country', selectedCountry);
    setSelectedCountry(selectedCountry);
  };

  const handleEmployeeStatusChange = (e) => {
    const selectedEmployeeStatus = e.target.value;
    setSelectedEmployeeStatus(selectedEmployeeStatus);
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    if (!isHRRole(selectedRole)) {
      setSelectedCountries([]);
      setRegionError("");
    }
  };

  useEffect(() => {
     /* logger.info('isAdmin:', isAdmin); */
    if (!isAdmin) {
      // Redirect non-admin users to home
      navigate('/home');
    }

  }, [isAdmin, navigate]);

  // Email Validation
  const validateEmail = () => {
    const emailRegex = /^[a-z.]+@atdxt\.com$/;
    //It checks the email is valid or not
    // ^[^\s@]+@: Starts with one or more characters that are not whitespace or '@',then it followed by @
    // [^\s@]+.: Followed by one or more characters that are not whitespace or '@',then it followed by .
    // [^\s@]+$: Ends with one or more characters that are not whitespace or '@'
    if (!email.trim()) {
      setEmailError("Company Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      logger.info(email);
    } else {
      setEmailError("");
    }
  }

  const validatePersonalEmail = (personalemail, email) => {
    const personalemailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!personalemail.trim()) {
      setPersonalEmailError("Personal Email is required");
    } else if (personalemail === email) {
      setPersonalEmailError("Personal Email cannot be the same as Company Email");
    } else if (!personalemailRegex.test(personalemail)) {
      setPersonalEmailError("Invalid personal email address");
    } else {
      setPersonalEmailError("");
    }
  }

  //Username Validation
  const validateUsername = () => {
    const minUsernameLength = 3;
    const maxUsernameLength = 25;
    const containsAlphabetRegex = /[a-zA-Z]/;   //To check if the username contains alphabet characters

    if (!username.trim()) {
      setUsernameError("Employee name is required");
    }  else if (!containsAlphabetRegex.test(username)) {
          setUsernameError("Employee name must contain alphabet character");
        } else if (username.length < minUsernameLength || username.length > maxUsernameLength) {
      setUsernameError(`Employee name must be between ${minUsernameLength} and ${maxUsernameLength} characters`);
    } else {
      setUsernameError("");
    }
  };

  //Phone Number Validation
  const validatePhoneNumber = () => {
    const phoneNumberRegex = /^\d+$/;   //To check if the phone number contains only digits

    if (!phone_number.trim() ) {
      setPhoneError("Active Mobile Number is required");
    } else if (!phoneNumberRegex.test(phone_number)) {
      setPhoneError("Phone Number must contain only digits");
    } else if (phone_number.length !== 10 ) {
      setPhoneError("Phone Number must have exactly 10 digits");
    } else {
      setPhoneError("");
    }
  };


  // Job Position Validation
  const validateJobPosition = () => {
    if (!selectedJobPosition) {
      setJobPositionError('Job Position is required');
    } else {
      setJobPositionError('');
    }
  };

  // Employee ID Validation
  const validateEmployeeId = () => {
    const employeeIdRegex = /^\d+$/;     //To check if the Employee Id contains only digits
    const maxEmployeeIdDigits = 5;

    if (!employee_id.trim()) {
      setEmployeeIdError("Employee ID is required");
    } else if (!employeeIdRegex.test(employee_id)) {
      setEmployeeIdError("Employee ID must contain only numbers");
    } else if (employee_id.length > maxEmployeeIdDigits) {
      setEmployeeIdError(`Employee ID must have at most ${maxEmployeeIdDigits} digits`);
    } else {
      setEmployeeIdError("");
    }
  };
 // Department Validation
 const validateDepartment = () => {
   if (!selectedDepartment || selectedDepartment === 'Select Department') {
     setDepartmentError('Please select Department');
   } else {
     setDepartmentError('');
   }
 };

  //Gender Validation
  const validateGender = () => {
     if (!selectedGender || selectedGender === 'Select Gender') {
        setGenderError('Please select a valid gender');
     } else if (!['OTHERS', 'FEMALE', 'MALE'].includes(selectedGender)) {
        setGenderError('Invalid gender selected');
     } else {
        setGenderError('');
     }
  };

  //Firstname Validation
  const validateFirstName = () => {
    const nameRegex = /^[A-Za-z ]{1,20}$/;   //To check the first name contains only letters and spaces, up to 20 characters

    if (!first_name.trim() ) {
      setFirstNameError("Firstname is required");
    } else if (!nameRegex.test(first_name)) {
      setFirstNameError("Invalid first name. Use only letters and spaces, up to 20 characters.");
    } else {
      setFirstNameError("");
    }
  };

  //Lastname Validation
  const validateLastName = () => {
    const nameRegex = /^[A-Za-z ]{1,20}$/;    //To check the Last name contains only letters and spaces, up to 20 characters

    if (!last_name.trim()) {
      setLastNameError("Lastname is required");
    } else if (!nameRegex.test(last_name)) {
      setLastNameError("Invalid last name. Use only letters and spaces, up to 20 characters.");
    } else {
      setLastNameError("");
    }
  };

  // Role Validation
  const validateRole = () => {
    if (!role || role === 0) {
      setRoleError("Role is required");
    } else {
      setRoleError("");
    }
    if (isHRRole(role) && selectedCountries.length === 0) {
      setRegionError("Region is required for HR role");
    } else {
      setRegionError("");
    }
  };

  // Current Address Validation
  const validateCurrentAddress = () => {
    if (!currentAddress.trim()) {
      setCurrentAddressError("Current Address is required");
    } else {
      setCurrentAddressError("");
    }
  };

  // Permanent Address Validation
  const validatePermanentAddress = () => {
    if (!permanentAddress.trim()) {
      setPermanentAddressError("Permanent Address is required");
    } else {
      setPermanentAddressError("");
    }
  };

  const validatecurrentState = () => {
    if (!currentState.trim()) {
      setcurrentStateError("State is required");
    } else {
      setcurrentStateError("");
    }
  };

  const validatepermanentState = () => {
    if (!permanentState.trim()) {
      setpermanentStateError("State is required");
    } else {
      setpermanentStateError("");
    }
  };

  const validatecurrentPostalCode = () => {
    const currentPostalCodeRegex = /^\d+$/;

    if (!currentPostalCode.trim()) {
      setcurrentPostalCodeError("Postal Code is required");
    } else if (!currentPostalCodeRegex.test(currentPostalCode)) {
      setcurrentPostalCodeError("Postal Code must have exactly 6 digits");
    } else if (currentPostalCode.length !== 6) {
      setcurrentPostalCodeError("Postal Code must have exactly 6 digits");
    } else {
      setcurrentPostalCodeError("");
    }
  };

  const validatepermanentPostalCode = () => {
    const permanentPostalCodeRegex = /^\d+$/;

    if (!permanentPostalCode.trim()) {
      setpermanentPostalCodeError("Postal Code is required");
    } else if (!permanentPostalCodeRegex.test(permanentPostalCode)) {
      setpermanentPostalCodeError("Postal Code must be a 6-digit number");
    } else if (permanentPostalCode.length !== 6) {
      setpermanentPostalCodeError("Postal Code must have exactly 6 digits");
    } else {
      setpermanentPostalCodeError("");
    }
  };

  const validatepermanentCountry = () => {
    if (!permanentCountry.trim()) {
      setpermanentCountryError("Permanent Country is required");
    } else {
      setpermanentCountryError("");
    }
  };

  const validatefamilyRelation = () => {
    if (!familyRelation.trim()) {
      setfamilyRelationError("Emergency Number Relation is required");
    } else {
      setfamilyRelationError("");
    }
  };

  const validatefamilyRelationName = () => {
      if (!familyRelationName.trim()) {
        setfamilyRelationNameError("Emergency Number Name is required");
      } else {
        setfamilyRelationNameError("");
      }
  };

  const validatefamilyRelationAddress = () => {
      if (!familyRelationAddress.trim()) {
         setfamilyRelationAddressError("Emergency Number Address is required");
      } else {
         setfamilyRelationAddressError("");
      }
  };

  const validatemaritalStatus = () => {
      if (!selectedMaritalStatus || selectedMaritalStatus === "Select Marital Status") {
         setmaritalStatusError("Marital Status is required");
      } else {
         setmaritalStatusError("");
      }
  };


  const validateaddressLine2 = () => {
    if (!addressLine2.trim()) {
      setaddressLine2Error("Address Line2 is required");
    } else {
      setaddressLine2Error("");
    }
  };

  // Blood Group Validation
  const validateBloodGroup = () => {
    if (!selectedBloodGroup || selectedBloodGroup === "Select BloodGroup") {
      setBloodGroupError("Blood Group is required");
    } else {
    setBloodGroupError("");
    }
  };

  // Family Contact Validation
  const validateFamilyContact = () => {
  const familyContactRegex = /^\d+$/;     //To check if the family conatct contains only digits
    if (!familyContact.trim()) {
      setFamilyContactError("Emergency Number is required");
    } else if (!familyContactRegex.test(familyContact)) {
      setFamilyContactError("Emergency Number must contain only digits");
    }
    else if (familyContact.length !== 10 ) {
      setFamilyContactError("Phone Number must have exactly 10 digits");
    }else {
    setFamilyContactError("");
    }
  };

  // Country Validation
  const validateCountry = () => {
   if (!selectedCountry) {
      setCountryError("Country is required");
   } else {
     setCountryError("");
   }
  };

  // Work location Validation
  const validateWorkLocation = () => {
    if (!selectedCountry) {
      setWorkLocationError("Work Location is required");
    } else {
      setWorkLocationError("");
    }
  };

  // Date of Birth Validation
  const validateDateOfBirth = () => {
    if (!date_of_birth.trim()) {
      setDateOfBirthError("Date of Birth is required");
    } else {
      const today = new Date();
      const dob = new Date(date_of_birth);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      if (age < 18) {
        setDateOfBirthError("Age must be at least 18 years old");
      } else {
        setDateOfBirthError("");
      }
    }
  };

  //Aadhar Validation
  const validateAadhar = () => {
    const aadharRegex = /^\d{12}$/; // To check if the Aadhar number contains exactly 12 digits
    if (selectedCountry === 'IN_BGL' || selectedCountry === 'IN_CBE') {
        if (!aadhar.trim()) {
          setAadharError("Aadhar Number is required");
        } else if (!aadharRegex.test(aadhar)) {
          setAadharError("Aadhar Number must contain 12 digits and no alphabets");
        } else {
          setAadharError("");
        }
    } else if (!aadharRegex.test(aadhar) && aadhar.trim()){
      setAadharError("Aadhar Number must contain 12 digits and no alphabets");
    } else {
      setAadharError("");
    }
  };

  // Pancard Number Validation
  const validatePancard = () => {
    const maxPancardLength = 12;
    const pancardRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;    //To check the Pancard number has the format ABCDE1234F
     if (selectedCountry === 'IN_BGL' || selectedCountry === 'IN_CBE' ) {
        if (!pancard.trim()) {
          setPancardError("Pancard Number is required");
        } else if (!pancardRegex.test(pancard)) {
        setPancardError("Pancard Number must have the format ABCDE1234F");
        } else if (pancard.length > maxPancardLength) {
        setPancardError(`Pancard Number must be at most ${maxPancardLength} characters`);
        } else {
          setPancardError("");
        }
     } else if (!pancardRegex.test(pancard) && pancard){
       setPancardError("Pancard Number must have the format ABCDE1234F");
     } else {
       setPancardError("");
     }
  };


  // Passport Number Validation
  const validatePassport = () => {
    const passportRegex = /^[A-Z0-9]+$/;

    if (selectedCountry && (selectedCountry !== 'IN_BGL' && selectedCountry !== 'IN_CBE')) {
        if (!passport.trim()) {
          setPassportError("Passport Number is required");
        } else if (!passportRegex.test(passport)) {
          setPassportError("Passport Number must have the format ABC1234567");
        } else {
          setPassportError("");
        }
      } else if (!passportRegex.test(passport) && passport) {
          setPassportError("Passport Number must have the format ABC1234567");
      } else {
      setPassportError("");
    }
  };

  // Joining Date Validation
  const validateJoiningDate = () => {
    if (!joiningDate.trim()) {
      setJoiningDateError("Joining Date is required");
    } else {
      setJoiningDateError("");
    }
  };
  const createdBy = localStorage.getItem('X-userId');
  const formData = new FormData();
  formData.append('createdBy', createdBy);

   const sendEmailNotification = (toEmail, generatedToken, username) => {
       logger.info('Email', toEmail);
       logger.info('Username', username);
       const emailRequest = {
           to: toEmail,
           token: generatedToken,
           username: username,
       };
       logger.info('EmailRequest', emailRequest);
   };

 const handleSubmit = (e) => {
  const userId = UserHeaders.USERID.value;
  logger.info("Submit button clicked");
  e.preventDefault();

  validateEmail();
  validatePersonalEmail(personalemail, email);
  validateUsername();
  validatePhoneNumber();
  validateJobPosition();
  validateEmployeeId();
  validateDepartment();
  validateGender();
  validateFirstName();
  validateLastName();
  validateRole();
  validateDateOfBirth();
  validateCurrentAddress();
  validatePermanentAddress();
  validateBloodGroup();
  validateFamilyContact();
  validateCountry();
  validatePancard();
  validateAadhar();
  validatePassport();
  validateJoiningDate();
  validatecurrentState();
  validatecurrentPostalCode();
  validatepermanentState();
  validatepermanentPostalCode();
  validatepermanentCountry();
  validatefamilyRelation();
  validateaddressLine2();
  validateWorkLocation();
  validatefamilyRelationName();
  validatefamilyRelationAddress();
  validatemaritalStatus();

  if (
    !email.trim() ||
    !personalemail.trim() ||
    !username.trim() ||
    !phone_number.trim() ||
    !selectedJobPosition.trim() ||
    !employee_id.trim() ||
    !selectedDepartment.trim() ||
    !selectedGender.trim() ||
    !first_name.trim() ||
    !last_name.trim() ||
    !role.trim() ||
    !date_of_birth.trim() ||
    !currentAddress.trim() ||
    !permanentAddress.trim() ||
    !familyContact.trim() ||
    !joiningDate.trim() ||
    !currentState.trim() ||
    !currentPostalCode.trim() ||
    !permanentState.trim() ||
    !permanentPostalCode.trim() ||
    !permanentCountry.trim() ||
    !familyRelation.trim() ||
    !addressLine2.trim() ||
    !familyRelationName.trim() ||
    !selectedMaritalStatus.trim() ||
    emailError ||
    personalemailError ||
    usernameError ||
    phoneError ||
    jobPositionError ||
    employeeIdError ||
    departmentError ||
    genderError ||
    first_name_error ||
    last_name_error ||
    roleError ||
    regionError ||
    dateOfBirthError ||
    currentAddressError ||
    permanentAddressError ||
    bloodGroupError ||
    familyContactError ||
    countryError ||
    pancardError ||
    aadharError ||
    passportError ||
    joiningDateError ||
    currentStateError ||
    currentPostalCodeError ||
    permanentStateError ||
    permanentPostalCodeError ||
    permanentCountryError ||
    familyRelationError ||
    addressLine2Error ||
    workLocationError ||
    familyRelationNameError ||
    familyRelationAddressError ||
    maritalStatusError
  ) {
    return;
  }

  setResponseMessage(null);

  fetch(`${API_BASE_URL}/users`, {
    method: "POST",
    headers: {
      'content-type': 'application/json',
      ...getAuthHeaders(accessToken),
      [UserHeaders.USERID.headerKey]: UserHeaders.USERID.value,
    },
    body: JSON.stringify({
      email: email,
      firstName: first_name,
      lastName: last_name,
      role: role,
      username: username,
      gender: selectedGender,
      jobPosition: selectedJobPosition,
      manager: selectedManagerId,
      phoneNumber: phone_number,
      employeeId: employee_id,
      department: selectedDepartment,
      createdBy: userId,
      personalDetails: {
        date_of_birth: date_of_birth,
        alternatephoneNumber: alternatephoneNumber,
        personalemail: personalemail,
        currentState: currentState,
        currentPostalCode: currentPostalCode,
        currentAddress: currentAddress,
        permanentAddress: permanentAddress,
        bloodGroup: selectedBloodGroup,
        familyContact: familyContact,
        country: selectedCountry,
        passport: passport,
        pancard: pancard,
        aadhar: aadhar,
        joiningDate: joiningDate,
        permanentState: permanentState,
        permanentPostalCode: permanentPostalCode,
        permanentCountry: permanentCountry,
        familyRelation: familyRelation,
        addressLine2: addressLine2,
        familyRelationName:familyRelationName,
        familyRelationAddress:familyRelationAddress,
        maritalStatus:selectedMaritalStatus,
      },
      regionAccessByRoles: {
        countriesId: selectedCountries,
      },
    })
  })
    .then((response) => response.json())
    .then((data) => {
      logger.info("Data from the server:", data);
      if (data.code === 201) {
        setResponseMessage({ description: data.description || "Registration successful.!!!!!" });
        setResponseCode(data.code);
        sendEmailNotification(email, data.generatedToken, username);
        logger.info('Usernameee', username);
        setTimeout(() => {
          logger.info("Inside setTimeout");
          setResponseCode('');
          navigate("/allusers");
        }, 2000);
      } else {
        logger.error('Registration failed:', data.description);
        setResponseMessage({ description: data.description || "Failed to register" });
      }
    })
    .catch((error) => {
      logger.error('Error:', error);
      setResponseMessage({ description: "Failed to register. Please try again." });
    });
 };

 const handleUsernameSelection = (selectedUsername) => {
  setSelectedUsername(selectedUsername);
 };

 const handleCheckboxChange = (event) => {
  const { value } = event.target;

  if (value === 'all') {
    if (selectedCountries.length === options.length - 1) {
      setSelectedCountries([]);
    } else {
      setSelectedCountries(
        options.map((option) => option.value).filter((val) => val !== 'all')
      );
    }
  } else {
    setSelectedCountries((prevSelectedCountries) => {
      if (prevSelectedCountries.includes(value)) {
        return prevSelectedCountries.filter((country) => country !== value);
      } else {
        return [...prevSelectedCountries, value];
      }
    });
  }
 };

 const toggleDropdown = () => {
  setDropdownOpen(!dropdownOpen);
 };

 const getSelectedCountriesLabels = () => {
  return options
    .filter((option) => selectedCountries.includes(option.value))
    .map((option) => option.label)
    .join(', ');
 };

 const selectedCountriesLabels = getSelectedCountriesLabels();

 const handleOutsideClick = (event) => {
  // Check if the clicked element is inside the dropdown container
  if (!event.target.closest('.custom-select-container')) {
    setDropdownOpen(false);
  }
 };
 document.body.addEventListener('click', handleOutsideClick);

  return (
      <div className="add-employee-body">
        <div className="forms-section">
          <form  onSubmit={handleSubmit}>
          <div className="form form-login">
            <div className="d-flex justify-content-between">
              <div>
                <div className="input-block">
                  <label htmlFor="officialId">
                    Employee ID <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="officialId"
                    name="officialId"
                    value={employee_id}
                    onBlur={validateEmployeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                    placeholder="Enter Employee ID"
                    className={employee_id ? "" : "input-placeholder"}
                  />
                  {employeeIdError && (
                    <p className="errors-message">
                      {employeeIdError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="officialUsername">
                    Employee Name <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="officialUsername"
                    name="officialUsername"
                    value={username}
                    onBlur={validateUsername}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter Employee Name"
                    className={username ? "" : "input-placeholder"}
                  />
                  {usernameError && (
                    <p className="errors-message">
                      {usernameError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="firstName">
                    Firstname <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={first_name}
                    onBlur={validateFirstName}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder="Enter Firstname"
                    className={first_name ? "" : "input-placeholder"}
                  />
                  {first_name_error && (
                    <p className="errors-message">
                      {first_name_error}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="lastName">
                    Lastname <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={last_name}
                    onBlur={validateLastName}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Enter Lastname"
                    className={last_name ? "" : "input-placeholder"}
                  />
                  {last_name_error && (
                    <p className="errors-message">
                      {last_name_error}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">


              <div>
                <div className="input-block">
                  <label htmlFor="joiningDate">
                    Joining Date <span class="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="joiningDate"
                    name="joiningDate"
                    value={joiningDate}
                    onBlur={validateJoiningDate}
                    onChange={(e) => setJoiningDate(e.target.value)}
                    placeholder="Enter Joining Date"
                    className={joiningDate ? "" : "input-placeholder"}
                  />
                  {joiningDateError && (
                    <p className="errors-message">
                      {joiningDateError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="officialDepartment">
                    Department <span class="text-danger">*</span>
                  </label>
                  <select
                    id="officialDepartment"
                    name="officialDepartment"
                    value={selectedDepartment}
                    onBlur={validateDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Enter Department"
                    className={selectedDepartment ? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      Select Department
                    </option>
                    {department.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {departmentError && (
                    <p className="errors-message">
                      {departmentError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="officialJobPosition">
                    Job Position <span class="text-danger">*</span>
                  </label>
                  <select
                    value={selectedJobPosition}
                    onBlur={validateJobPosition}
                    onChange={handleJobPositionChange}
                    id="jobPosition"
                    name="jobPosition"
                    className="textbox select-box"
                    className={selectedJobPosition ? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      {selectedJobPosition ? "Select Job Position" : "Select Job Position"}
                    </option>
                    {jobPositions.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {jobPositionError && <p className="error-message">{jobPositionError}</p>}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="gender">
                    Gender <span class="text-danger">*</span>
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={selectedGender}
                    onBlur={validateGender}
                    onChange={(e) => setSelectedGender(e.target.value)}
                    placeholder="Enter Gender"
                    className={ selectedGender? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    {gender.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {genderError && (
                    <p className="errors-message">
                      {genderError}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <div className="input-block">
                  <label htmlFor="officialEmail">
                    Company Email <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="officialEmail"
                    name="officialEmail"
                    value={email}
                    onBlur={validateEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Company Email"
                    className={email ? "" : "input-placeholder"}
                  />
                  {emailError && (
                    <p className="errors-message">
                      {emailError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="personalemail">Personal Email <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    id="personalemail"
                    name="personalemail"
                    value={personalemail}
                    onBlur={() => validatePersonalEmail(personalemail, email)}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                    placeholder="Enter Personal Email"
                    className={personalemail ? "" : "input-placeholder"}
                  />
                  {personalemailError && (
                    <p className="errors-message">
                      {personalemailError}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div className="input-block">
                  <label htmlFor="phoneNumber">
                    Active Mobile Number <span class="text-danger">*</span>
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phone_number}
                    onBlur={validatePhoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const truncatedValue = sanitizedValue.slice(0, 10);
                      setPhoneNumber(truncatedValue);
                      validatePhoneNumber(truncatedValue);
                    }}
                    placeholder="Enter Active mobile Number"
                    className={phone_number ? "" : "input-placeholder"}
                  />
                  {phoneError && (
                    <p className="errors-message">
                      {phoneError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="alternatephoneNumber">Alternate MobileNo</label>
                  <input
                    type="tel"
                    id="alternatephoneNumber"
                    name="alternatephoneNumber"
                    value={alternatephoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const truncatedValue = sanitizedValue.slice(0, 10);
                      setAlternatePhoneNumber(truncatedValue);
                      /*    validateAlternatePhoneNumber(truncatedValue); */
                    }}
                    placeholder="Enter Alternate MobileNo"
                    className={alternatephoneNumber ? "" : "input-placeholder"}
                  />
                  {alternatephoneError && (
                    <p className="errors-message">
                      {alternatephoneError}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <div className="input-block">
                  <label htmlFor="role" >
                    Role <span class="text-danger">*</span>
                  </label>{" "}
                  <select
                    value={role}
                    onChange={handleRoleChange}
                    onBlur={validateRole}
                    id="role"
                    name="role"
                    placeholder="Enter Role"
                    className={role ? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {userTypes.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.enumValue}
                      </option>
                    ))}
                  </select>
                  {roleError && (
                    <p className="errors-message">
                      {roleError}
                    </p>
                  )}
                </div>
              </div>

              <div className="multi-select-container input-block ">
                <label htmlFor="region" className="multi-select-label">
                  Region {isHRRole(role) && <span className="text-danger">*</span>}
                </label>
                <div className="custom-select-container" onBlur={validateRole}>
                  <div
                    className={`custom-select-header ${selectedCountries.length === 0 ? 'input-placeholder' : ''}`}
                    onClick={isHRRole(role) ? toggleDropdown : null}
                  >
                    <div className={`custom-select-text-wrapper ${selectedCountries.length === 0 ? 'input-placeholder' : ''}`}>
                      <span className="custom-select-text">
                        {selectedCountries.length > 0 ? selectedCountriesLabels : 'Select Region'}
                      </span>
                    </div>
                  </div>
                  {isHRRole(role) ? (
                    dropdownOpen && (
                      <div className="custom-select-dropdown">
                        {options.map((option) => (
                          <div key={option.value} className="custom-select-option">
                            <input
                              type="checkbox"
                              id={`checkbox-${option.value}`}
                              value={option.value}
                              checked={
                                option.value === 'all'
                                  ? selectedCountries.length === options.length - 1
                                  : selectedCountries.includes(option.value)
                              }
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={`checkbox-${option.value}`}>{option.label}</label>
                          </div>
                        ))}
                      </div>
                    )
                  ) : null}
                  {regionError && (
                    <p className="errors-message">
                      {regionError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="officialManager">Manager</label>
                  <select
                    id="officialManager"
                    name="officialManager"
                    value={selectedUsername}
                    onChange={(e) => {
                      setSelectedManagerId(e.target.value);
                      handleUsernameSelection(e.target.value);
                    }}
                    placeholder="Enter Manager"
                    className={selectedUsername ? "" : "input-placeholder"}
                  >
                    <option value="">Select Manager</option>
                    {Array.isArray(manager) ? (
                      manager.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading...</option>
                    )}
                  </select>
                  {managerError && (
                    <p className="errors-message">{managerError}</p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="country">Work Location <span class="text-danger">*</span> </label>
                  <select
                    id="country"
                    name="country"
                    value={selectedCountry}
                    onBlur={validateWorkLocation}
                    onChange={handleCountryChange}
                    placeholder="Enter Work location"
                    className={selectedCountry ? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      Select Work location
                    </option>
                    {countries.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {workLocationError && (
                    <p className="errors-message">
                      {workLocationError}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
            ></div>

            <div className="d-flex justify-content-between">
            </div>

          <div className="d-flex justify-content-between" >
            <div>
              <div className="input-block">
                 <label htmlFor="passport">Passport Number {selectedCountry && (selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL') &&  <span class="text-danger">*</span>}</label>
                  <input
                     type="text"
                     id="passport"
                     name="passport"
                     value={passport}
                     onBlur={validatePassport}
                     onChange={(e) => setPassport(e.target.value)}
                     placeholder="Enter Passport Number"
                     className={passport ? "" : "input-placeholder"}
                  />
                   {passportError && (
                      <p className="errors-message">
                         {passportError}
                      </p>
                   )}
              </div>
            </div>

              <div>
                <div className="input-block">
                  <label htmlFor="pancard">
                    Pancard Number {(selectedCountry === 'IN_BGL' || selectedCountry === 'IN_CBE') &&  <span class="text-danger">*</span>}
                  </label>
                  <input
                    type="text"
                    id="pancard"
                    name="pancard"
                    value={pancard}
                    onBlur={validatePancard}
                    onChange={(e) => setPancard(e.target.value)}
                    placeholder="Enter Pancard Number"
                    className={pancard ? "" : "input-placeholder"}
                  />
                  {pancardError && (
                    <p className="errors-message">
                      {pancardError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="aadhar">
                    Aadhar Number {(selectedCountry === 'IN_BGL' || selectedCountry === 'IN_CBE')  &&  <span class="text-danger">*</span>}
                  </label>
                  <input
                    type="text"
                    id="aadhar"
                    name="aadhar"
                    value={aadhar}
                    onBlur={validateAadhar}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const truncatedValue = sanitizedValue.slice(0, 12);
                      setAadhar(truncatedValue);
                      validateAadhar(truncatedValue);
                    }}
                    placeholder="Enter Aadhar Number"
                    className={aadhar ? "" : "input-placeholder"}
                  />
                  {aadharError && (
                    <p className="errors-message">
                      {aadharError}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="bloodGroup">
                      Blood Group <span class="text-danger">*</span>
                  </label>
                  <select
                      id="bloodGroup"
                      name="bloodGroup"
                      value={selectedBloodGroup}
                      onBlur={validateBloodGroup}
                      onChange={handleBloodGroupChange}
                      placeholder="Enter Blood Group"
                      className={selectedBloodGroup ? "" : "input-placeholder"}
                  >
                    <option value="" disabled>
                      Select Blood Group
                    </option>
                    {bloodGroup.map((option, index) => (
                      <option key={index} value={option.enumValue}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {bloodGroupError && (
                    <p className="errors-message">
                      {bloodGroupError}
                    </p>
                  )}
                </div>
              </div>
             </div>
              <div className="d-flex justify-content-between">
              <div>
                <div className="input-block">
                   <label htmlFor="dateOfBirth">
                     Date Of Birth <span class="text-danger">*</span>
                   </label>
                 <input
                   type="date"
                   id="dateOfBirth"
                   name="dateOfBirth"
                   value={date_of_birth}
                   onBlur={validateDateOfBirth}
                   onChange={(e) => setDateOfBirth(e.target.value)}
                   max={new Date().toISOString().split("T")[0]}
                   placeholder="Enter Date Of Birth"
                   className={date_of_birth ? "" : "input-placeholder"}
                 />
                 {dateOfBirthError && (
                 <p className="errors-message">{dateOfBirthError}</p>)}
                </div>
              </div>

              <div>
                <div className="input-block">
                  <label htmlFor="maritalStatus">
                    Marital Status <span class="text-danger">*</span>
                  </label>
                  <select
                     id="maritalStatus"
                     name="maritalStatus"
                     value={selectedMaritalStatus}
                     onBlur={validatemaritalStatus}
                     onChange={handleMaritalStatusChange}
                     placeholder="Enter Marital Status"
                     className={selectedMaritalStatus ? "" : "input-placeholder"}
                  >
                  <option value="" disabled>
                     Select Marital Status
                  </option>
                  {maritalStatus.map((option, index) => (
                     <option key={index} value={option.enumValue}>
                       {option.name}
                     </option>
                  ))}
                </select>
                 {maritalStatusError&& ( <p className="errors-message">{maritalStatusError} </p> )}
                </div>
              </div>
            </div>

            <p className="mt-3 mb-3 address-title">Emergency Contact Details</p>
            <div>
              <div className="d-flex justify-content-between  contact-section">
                <div>
                   <div className="input-block">
                      <label htmlFor="familyContact">
                        Contact Number{" "}<span class="text-danger">*</span>
                      </label>
                      <input
                         type="text"
                         id="familyContact"
                         name="familyContact"
                         value={familyContact}
                         onBlur={validateFamilyContact}
                         onChange={(e) => {
                         const sanitizedValue = e.target.value.replace(/\D/g, "");
                         const truncatedValue = sanitizedValue.slice(0, 10);
                         setFamilyContact(truncatedValue);
                         validateFamilyContact(truncatedValue);
                         }}
                         placeholder="Enter Emergency Number"
                         className={familyContact ? "" : "input-placeholder"}
                      />
                      {familyContactError && (<p className="errors-message">{familyContactError}</p>)}
                   </div>
                </div>
                 <div>
                   <div className="input-block">
                     <label htmlFor="familyRelationName">
                      Name <span class="text-danger">*</span>
                     </label>
                     <input
                        type="text"
                        id="familyRelationName"
                        name="familyRelationName"
                        value={familyRelationName}
                        onBlur={validatefamilyRelationName}
                        onChange={(e) => setfamilyRelationName(e.target.value)}
                        placeholder="Enter Emergency Number Relation Name"
                        className={familyRelationName ? "" : "input-placeholder"}
                     />
                     {familyRelationNameError && (<p className="errors-message">{familyRelationNameError} </p> )}
                   </div>
                 </div>
                 <div>
                   <div className="input-block">
                     <label htmlFor="familyRelation">
                        Relationship <span class="text-danger">*</span>
                     </label>
                     <input
                        type="text"
                        id="familyRelation"
                        name="familyRelation"
                        value={familyRelation}
                        onBlur={validatefamilyRelation}
                        onChange={(e) => setfamilyRelation(e.target.value)}
                        placeholder="Enter Emergency Number Relation"
                        className={familyRelation ? "" : "input-placeholder"}
                     />
                     {familyRelationError && (<p className="errors-message">{familyRelationError}</p>)}
                   </div>
                 </div>

                 <div>
                    <div className="input-block">
                       <label htmlFor="familyRelationAddress">
                         Address <span class="text-danger">*</span>
                       </label>
                        <input
                          type="text"
                          id="familyRelationAddress"
                          name="familyRelationAddress"
                          value={familyRelationAddress}
                          onBlur={validatefamilyRelationAddress}
                          onChange={(e) => setfamilyRelationAddress(e.target.value)}
                          placeholder="Enter Emergency Number Relation Address"
                          className={familyRelationAddress ? "" : "input-placeholder"}
                        />
                         {familyRelationAddressError && ( <p className="errors-message">{familyRelationAddressError} </p> )}
                    </div>
                 </div>
              </div>
            </div>

            <p className="mt-3 mb-3 address-title">Current Address</p>
            <div>
              <div className="d-flex justify-content-between  address-section">
                <div>
                  <div className="input-block">
                    <label htmlFor="currentAddress">
                      Address Line1<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="currentAddress"
                      name="currentAddress"
                      value={currentAddress}
                      onBlur={validateCurrentAddress}
                      onChange={(e) => setCurrentAddress(e.target.value)}
                      placeholder="Enter Address"
                      className={currentAddress ? "" : "input-placeholder"}
                    />
                    {currentAddressError && (
                      <p className="errors-message">
                        {currentAddressError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="addressLine2">
                      Address Line2<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="addressLine2"
                      name="addressLine2"
                      value={addressLine2}
                      onBlur={validateaddressLine2}
                      onChange={(e) => setaddressLine2(e.target.value)}
                      placeholder="Enter Address"
                      className={addressLine2 ? "" : "input-placeholder"}
                    />
                    {addressLine2Error && (
                      <p className="errors-message">
                        {addressLine2Error}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="currentState">
                      State <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="currentState"
                      name="currentState"
                      value={currentState}
                      onChange={(e) => setcurrentState(e.target.value)}
                      placeholder="Enter State"
                      className={currentState ? "" : "input-placeholder"}
                    />
                    {currentStateError && (
                      <p className="errors-message">
                        {currentStateError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="currentPostalCode">
                      Postal Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="currentPostalCode"
                      name="currentPostalCode"
                      value={currentPostalCode}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/\D/g, "");
                        const truncatedValue = sanitizedValue.slice(0, 6);
                        setcurrentPostalCode(truncatedValue);
                        validatecurrentPostalCode(truncatedValue);
                      }}
                      placeholder="Enter Postal Code"
                      className={currentPostalCode ? "" : "input-placeholder"}
                    />
                    {currentPostalCodeError && (
                      <p className="errors-message">
                        {currentPostalCodeError}
                      </p>
                    )}
                  </div>
                </div>
              </div>


            <p className="mt-3 mb-3 address-title">Permanent Address</p>
            <div>
              <div className="d-flex justify-content-between  address-section">
                <div>
                  <div className="input-block">
                    <label htmlFor="permanentAddress">
                      HouseNo/Street/Landmark
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="permanentAddress"
                      name="permanentAddress"
                      value={permanentAddress}
                      onBlur={validatePermanentAddress}
                      onChange={(e) => setPermanentAddress(e.target.value)}
                      placeholder="Enter Address"
                      className={permanentAddress ? "" : "input-placeholder"}
                    />
                    {permanentAddressError && (
                      <p className="errors-message">
                        {permanentAddressError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="permanentCountry">
                      Country <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="permanentCountry"
                      name="permanentCountry"
                      value={permanentCountry}
                      onBlur={validatepermanentCountry}
                      onChange={(e) => setpermanentCountry(e.target.value)}
                      placeholder="Enter Country"
                      className={permanentCountry ? "" : "input-placeholder"}
                    />
                    {permanentCountryError && (
                      <p  className="errors-message">
                        {permanentCountryError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="permanentState">
                      State <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="permanentState"
                      name="permanentState"
                      value={permanentState}
                      onBlur={validatepermanentState}
                      onChange={(e) => setpermanentState(e.target.value)}
                      placeholder="Enter State"
                      className={permanentState ? "" : "input-placeholder"}
                    />
                    {permanentStateError && (
                      <p className="errors-message">
                        {permanentStateError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="input-block">
                    <label htmlFor="permanentPostalCode">
                      Postal Code <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="permanentPostalCode"
                      name="permanentPostalCode"
                      value={permanentPostalCode}
                      onBlur={validatepermanentPostalCode}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/\D/g, "");
                        const truncatedValue = sanitizedValue.slice(0, 6);
                        setpermanentPostalCode(truncatedValue);
                        validatepermanentPostalCode(truncatedValue);
                      }}
                      placeholder="Enter Postal Code"
                      className={permanentPostalCode ? "" : "input-placeholder"}
                    />
                    {permanentPostalCodeError && (
                      <p className="errors-message">
                        {permanentPostalCodeError}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
              <div>
                  {responseMessage && (
                      <div className={responseCode === 201 ? "success-message" : "error-messages"}>
                          <p>{responseMessage.description}</p>
                      </div>
                  )}
              </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="form-button">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default AddEmployee;