// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.birthday-container {
  font-family: Arial, sans-serif;
  background: #FFFFFF;
  padding: 20px;
  box-shadow: 4px 7px 21px 1px #00000033;
  border-radius: 20px;
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.birthday-container h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #242D67;
}

.custom-birthday-container {
    text-align: left;
    width: 100%;
    max-width: 680px;
    height: auto;
    margin: 3.8rem auto;
    display: flex;
    flex-direction: column;
    align-items: left;
}`, "",{"version":3,"sources":["webpack://./src/styles/DobAnniversary.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,sCAAsC;EACtC,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".birthday-container {\n  font-family: Arial, sans-serif;\n  background: #FFFFFF;\n  padding: 20px;\n  box-shadow: 4px 7px 21px 1px #00000033;\n  border-radius: 20px;\n  text-align: left;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n}\n\n.birthday-container h1 {\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 24px;\n  font-weight: 600;\n  color: #242D67;\n}\n\n.custom-birthday-container {\n    text-align: left;\n    width: 100%;\n    max-width: 680px;\n    height: auto;\n    margin: 3.8rem auto;\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
