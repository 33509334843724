import React, {
    useState,
    useEffect
} from "react";
import {
    useNavigate
} from "react-router-dom";
import "./styles/login.css";
import { API_BASE_URL } from './constant/apiConstants';
import logger from "./logs/logger";

export const Register = (props) => {
const [userTypes, setUserTypes] = useState([]);
const [email, setEmail] = useState('');
const [emailError, setEmailError] = useState('');
const [username, setUsername] = useState('');
const [usernameError, setUsernameError] = useState('');
const [first_name, setFirstname] = useState('');
const [first_name_error, setFirstNameError] = useState('');
const [last_name, setLastname] = useState('');
const [last_name_error, setLastNameError] = useState('');
const [pass, setPass] = useState('');
const [passError, setPassError] = useState('');
const [confirm_password, setConfirmpassword] = useState('');
const [confirmPassError, setConfirmPassError] = useState('');
const [phone_number, setPhonenumber] = useState('');
const [phoneError, setPhoneError] = useState('');
const [date_of_birth, setDateofbirth] = useState('');
const [dateOfBirthError, setDateOfBirthError] = useState('');
const [role, setRole] = useState('');
const [roleError, setRoleError] = useState('');
const [registerResponse, setRegisterResponse] = useState(null);
const navigate = useNavigate();

 useEffect(() => {
   const fetchDropdownOptions = async () => {
     try {
       const response = await fetch(`${API_BASE_URL}/metadata/usertypes`);
       if (response.ok) {
         const data = await response.json();
         const optionsArray = Object.keys(data).map((key) => {
           return {
             name: data[key].name,
             description: data[key].description,
             enumValue: key,
             id: data[key].id
           };
         });
         setUserTypes(optionsArray);
       } else {
         logger.error('Failed to fetch dropdown options');
       }
     } catch (error) {
       logger.error('An error occurred while fetching dropdown options:', error);
     }
   };

   fetchDropdownOptions();
 }, []);

  const handleRedirectToLogin = () => {
    navigate("/login");
  }
//Email Validation
 const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
        setEmailError("Email is required");
      } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  }

//Username Validation
const validateUsername = () => {
  const minUsernameLength = 3;
  const maxUsernameLength = 15;
  const containsAlphabetRegex = /[a-zA-Z]/;

  if (!username.trim()) {
    setUsernameError("Username is required");
  }  else if (!containsAlphabetRegex.test(username)) {
        setUsernameError("Username must contain alphabet character");
      } else if (username.length < minUsernameLength || username.length > maxUsernameLength) {
    setUsernameError(`Username must be between ${minUsernameLength} and ${maxUsernameLength} characters`);
  } else {
    setUsernameError("");
  }
};

//Firstname Validation
const validateFirstName = () => {
  const nameRegex = /^[A-Za-z ]{1,20}$/;

  if (!first_name.trim() ) {
          setFirstNameError("Firstname is required");
        } else if (!nameRegex.test(first_name)) {
    setFirstNameError("Invalid first name. Use only letters and spaces, up to 20 characters.");
  } else {
    setFirstNameError("");
  }
};

//Lastname Validation

const validateLastName = () => {
  const nameRegex = /^[A-Za-z ]{1,20}$/;

  if (!last_name.trim()) {
            setLastNameError("Lastname is required");
          } else if (!nameRegex.test(last_name)) {
    setLastNameError("Invalid last name. Use only letters and spaces, up to 20 characters.");
  } else {
    setLastNameError("");
  }
};

//Password Validation
const validatePassword = () => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
  if (!pass.trim()) {
              setPassError("Password is required");
            } else if (!passwordRegex.test(pass)) {
    setPassError("Password must be 8-20 characters and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.");
  } else {
    setPassError("");
  }
};

//ConfirmPassword Validation
const validateConfirmPassword = () => {

  if (!confirm_password.trim()) {
              setConfirmPassError("ConfirmPassword is required");
            } else if (pass !== confirm_password) {
    setConfirmPassError("Passwords do not match.");
  } else {
    setConfirmPassError("");
  }
};

//Phone number Validation
const validatePhoneNumber = () => {
 if (!phone_number.trim()) {
     setPhoneError("Phone Number is required");
   } else if (phone_number.length < 10) {
         setPhoneError("Phone Number must have at least 10 digits");
       } else {
     setPhoneError("");
   }
};

// Date of Birth Validation
  const validateDateOfBirth = () => {
    if (!date_of_birth.trim()) {
      setDateOfBirthError("Date of Birth is required");
    } else {
       const formattedDate = new Date(date_of_birth).toISOString().split('T')[0];
          setDateofbirth(formattedDate);
          setDateOfBirthError("");
    }
  };

// Role Validation
   const validateRole = () => {
       if (!role || role === 0) {
         setRoleError("Role is required");
       } else {
         setRoleError("");
       }
     };

const handleSubmit =  (e) => {
e.preventDefault();

validateEmail();
validateUsername();
validateFirstName();
validateLastName();
validatePassword();
validateConfirmPassword();
validatePhoneNumber();
validateDateOfBirth();
validateRole();

   if (!email.trim() ||
           !username.trim() ||
           !first_name.trim() ||
           !last_name.trim() ||
           !pass.trim() ||
           !confirm_password.trim() ||
           !phone_number.trim() ||
           !date_of_birth.trim() ||
           !role ||
      emailError ||
      usernameError ||
      first_name_error ||
      last_name_error ||
      passError ||
      confirmPassError ||
      phoneError ||
      dateOfBirthError ||
      roleError
    ) {
      return;
    }
     setRegisterResponse(null);

 fetch(`${API_BASE_URL}/users`, {
      method: "POST",
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        email: email,
        username: username,
        first_name: first_name,
        last_name: last_name,
        password: pass,
        confirm_password: confirm_password,
        phone_number: phone_number,
        date_of_birth: date_of_birth,
        role: role
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 201) {
        setRegisterResponse({ description: data.description || "Registration successful.!!!!!" });

        setTimeout(() => {
                        logger.info("Inside setTimeout");
                          navigate("/login");
                        }, 2000);

        } else {
          logger.error('Registration failed:', data.description);
          setRegisterResponse({ description:data.description || "Failed to register" });
        }
      })
      .catch((error) => {
        logger.error('Error:', error);
        setRegisterResponse({ description: "Failed to register. Please try again."
         });

      });
  };


return(
<div className="login-container">
      <div className="login-image-container">
      </div>
<div className="login-form-container">
<h2>Register</h2>
<form className="register-form" onSubmit={handleSubmit}>
<div style={{ display: 'flex', justifyContent: 'space-between' }}>
<div style={{ width: '48%' }}>
<label htmlFor="email">Email</label>
<input value={email} onChange={(e) => setEmail(e.target.value)} onBlur={validateEmail} type="text" placeholder="youremail@gmail.com" id="email" name="email" />
{emailError && <p style={{ color: 'red' }} className="error-message">{emailError}</p>}
</div>
<div style={{ width: '48%' }}>
<label htmlFor="username">User Name</label>
<input value={username} onChange={(e) => setUsername(e.target.value)} onBlur={validateUsername} type="username" placeholder="User Name" id="username" name="username" />
{usernameError && <p style={{ color: 'red' }} className="error-message">{usernameError}</p>}
</div>
 </div>
 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
 <div style={{ width: '48%' }}>
<label htmlFor="first_name">First Name</label>
<input value={first_name} onChange={(e) => setFirstname(e.target.value)} onBlur={validateFirstName} type="first_name" placeholder="First Name" id="first_name" name="first_name" />
{first_name_error && <p style={{ color: 'red' }} className="error-message">{first_name_error}</p>}
</div>
 <div style={{ width: '48%' }}>
<label htmlFor="last_name">Last Name</label>
<input value={last_name} onChange={(e) => setLastname(e.target.value)} onBlur={validateLastName} type="last_name" placeholder="Last Name" id="last_name" name="last_name" />
{last_name_error && <p style={{ color: 'red' }} className="error-message">{last_name_error}</p>}
</div>
</div>
<div style={{ display: 'flex', justifyContent: 'space-between' }}>
 <div style={{ width: '48%' }}>
<label htmlFor="password">Password</label>
<input value={pass} onChange={(e) => setPass(e.target.value)} onBlur={validatePassword} type="password" placeholder="**********" id="password" name="password" />
{passError && <p style={{ color: 'red' }} className="error-message">{passError}</p>}
</div>
<div style={{ width: '48%' }}>
<label htmlFor="confirm_password">Confirm Password</label>
<input value={confirm_password} onChange={(e) => setConfirmpassword(e.target.value)} onBlur={validateConfirmPassword} type="password" placeholder="**********" id="confirm_password" name="confirm_password" />
{confirmPassError && <p style={{ color: 'red' }} className="error-message">{confirmPassError}</p>}
</div>
</div>
<div style={{ display: 'flex', justifyContent: 'space-between' }}>
 <div style={{ width: '48%' }}>
<label htmlFor="phone_number">Phone Number</label>
<input value={phone_number} onChange={(e) => {
const sanitizedValue = e.target.value.replace(/\D/g, '');
const truncatedValue = sanitizedValue.slice(0, 10);
setPhonenumber(truncatedValue);
validatePhoneNumber(truncatedValue);
}} onBlur={validatePhoneNumber} type="tel" placeholder="Phone Number" id="phone_number" name="phone_number" />
{phoneError && <p style={{ color: 'red' }} className="error-message">{phoneError}</p>}
</div>

<div style={{ width: '48%' }}>
<label htmlFor="date_of_birth">Date of Birth</label>
        <input
          type="date"
          value={date_of_birth}
          onChange={(e) => setDateofbirth(e.target.value)}
          onBlur={validateDateOfBirth}
          id="date_of_birth"
          name="date_of_birth"
        />
        {dateOfBirthError && (<p style={{ color: 'red' }} className="error-message"> {dateOfBirthError} </p> )}
</div>
</div>
&nbsp;
&nbsp;

<div style={{ width: '48%' }}>
<label htmlFor="role">Role</label>
        <select value={role} onChange={(e) => setRole(e.target.value)} onBlur={validateRole} id="role" name="role"  style={{ padding: '1rem', border: 'none', borderRadius: '8px', }}>>
           {userTypes && userTypes.length > 0 && userTypes.map((option, index) => (
                <option key={index} value={option.enumValue}>
                  {option.name}
                </option>
              ))}
        </select>
        {roleError && ( <p style={{ color: 'red' }} className="error-message"> {roleError} </p> )}
    </div>
    &nbsp;
<button type="submit">Register</button>
</form>
<button className="link-btn" onClick={handleRedirectToLogin} >Already have an account? Login here.</button>
  <div>
      {registerResponse && (
        <div className="register-response" style={{ color: registerResponse.errorcode === 201 ? 'green' : 'red' }} >
          <p>{registerResponse.description}</p>
        </div>
      )}
       </div>
</div>
</div>
);
};