import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./styles/EditUserPage.css";
import { API_BASE_URL } from './constant/apiConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import logger from './logs/logger';
import { UserHeaders } from './constant/localStorageConstants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditUserPage = ({ onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({
    personalDetails: {
      date_of_birth: '',
      alternatephoneNumber: '',
      personalemail: '',
      currentAddress: '',
      permanentAddress: '',
      bloodGroup: '',
      familyContact: '',
      country: '',
      passport: '',
      pancard: '',
      aadhar: '',
      profile_photo: '',
      joiningDate: '',
      currentState: '',
      currentPostalCode: '',
      permanentState: '',
      permanentPostalCode: '',
      permanentCountry: '',
      familyRelation: '',
      addressLine2: '',
      familyRelationName: '',
      familyRelationAddress: '',
    },
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const USERID = location.state && location.state.userId;
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [first_name_error, setFirstNameError] = useState('');
  const [last_name_error, setLastNameError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [employeeIdError, setEmployeeIdError] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [manager, setManager] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');
  const [managerError, setManagerError] = useState('');
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState('');
  const [jobPositionError, setJobPositionError] = useState('');
  const [jobPositionTouched, setJobPositionTouched] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [personalemail, setPersonalEmail] = useState('');
  const [personalemailError, setpersonalemailError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [alternatephoneError, setAlternatePhoneError] = useState('');
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [permanentAddressError, setPermanentAddressError] = useState('');
  const [bloodGroup, setBloodGroup] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
  const [bloodGroupError, setBloodGroupError] = useState('');
  const [familyContactError, setFamilyContactError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [passportError, setPassportError] = useState('');
  const [pancardError, setPancardError] = useState('');
  const [aadharError, setAadharError] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [joiningDateError, setJoiningDateError] = useState('');
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState('');
  const [employeeStatusError, setEmployeeStatusError] = useState('');
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [lastWorkingDate, setLastWorkingDate] = useState('');
  const [lastWorkingDateError, setlastWorkingDateError] = useState('');
  const [currentState, setcurrentState] = useState('');
  const [currentStateError, setcurrentStateError] = useState('');
  const [currentPostalCode, setcurrentPostalCode] = useState('');
  const [currentPostalCodeError, setcurrentPostalCodeError] = useState('');
  const [permanentState, setpermanentState] = useState('');
  const [permanentStateError, setpermanentStateError] = useState('');
  const [permanentPostalCode, setpermanentPostalCode] = useState('');
  const [permanentPostalCodeError, setpermanentPostalCodeError] = useState('');
  const [permanentCountry, setpermanentCountry] = useState('');
  const [permanentCountryError, setpermanentCountryError] = useState('');
  const [familyRelation, setfamilyRelation] = useState('');
  const [familyRelationError, setfamilyRelationError] = useState('');
  const [addressLine2, setaddressLine2] = useState('');
  const [addressLine2Error, setaddressLine2Error] = useState('');
  const [isAadharReadOnly, setIsAadharReadOnly] = useState(false);
  const [isPancardReadOnly, setIsPancardReadOnly] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState(editedUser.personalDetails?.regions || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passport, setPassport] = useState('');
  const [pancard, setPancard] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [showRegionSelection, setShowRegionSelection] = useState(false);
  const [hasRun, setHasRun] = useState(false);
  const [regionError, setRegionError] = useState('');
  const [familyRelationName, setfamilyRelationName] = useState("");
  const [familyRelationNameError, setfamilyRelationNameError] = useState('');
  const [familyRelationAddress, setfamilyRelationAddress] = useState("");
  const [familyRelationAddressError, setfamilyRelationAddressError] = useState("");
  const [maritalStatus, setmaritalStatus] = useState([]);
  const [maritalStatusError, setmaritalStatusError] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const handleUsernameSelection = (selectedUsername) => {
    setSelectedUsername(selectedUsername);
  };

  const isHRRole = (role) => {
    return role === 'HR';
  };

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/countrystatus`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
       });
        if (response.ok) {
          const data = await response.json();
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setCountries(countryArray);
        } else {
          logger.error('Failed to fetch country options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, [accessToken]);

  useEffect(() => {
    const countryValue = editedUser.personalDetails?.country.name;
    updateReadOnlyFields(countryValue);
  }, [editedUser.personalDetails?.country.name]);

  //Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[a-z.]+@atdxt\.com$/;
    //It checks the email is valid or not
    // ^[^\s@]+@: Starts with one or more characters that are not whitespace or '@',then it followed by @
    // [^\s@]+.: Followed by one or more characters that are not whitespace or '@',then it followed by .
    // [^\s@]+$: Ends with one or more characters that are not whitespace or '@'
    if (!email.trim()) {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  }

  //Username Validation
  const validateUsername = (username) => {
    const minUsernameLength = 3;
    const maxUsernameLength = 15;
    const containsAlphabetRegex = /^[a-zA-Z]+$/;   //To check if the username contains alphabet characters

    username = username.trim(); // Trim leading and trailing spaces

    if (!username) {
      setUsernameError("Username is required");
    }else if (!containsAlphabetRegex.test(username)) {
      setUsernameError("Username must contain alphabet character");
    } else if (username.length < minUsernameLength || username.length > maxUsernameLength) {
      setUsernameError(`Username must be between ${minUsernameLength} and ${maxUsernameLength} characters`);
    } else {
      setUsernameError("");
    }
  };

  //Phone Number Validation
  const validatePhoneNumber = (phone_numberGet) => {
    const phoneNumberRegex = /^\d+$/; // To check if the phone number contains only digits
    const phone_number = phone_numberGet.toString();

    if (!phone_number.trim()) {
      setPhoneError("Phone Number is required");
    } else if (!phoneNumberRegex.test(phone_number)) {
      setPhoneError("Phone Number must contain only digits");
    } else if (phone_number.length !== 10) {
      setPhoneError("Phone Number must have exactly 10 digits");
    } else {
      setPhoneError("");
    }
  };

  // Alternate Phone Number Validation
  const validateAlternatePhoneNumber = (alternatephoneNumberGet) => {
    const phoneNumberRegex = /^\d+$/; // To check if the phone number contains only digits
    if (alternatephoneNumberGet) {
      const alternatephoneNumber = alternatephoneNumberGet.toString();
      if (!alternatephoneNumber.trim()) {
        setAlternatePhoneError("Phone Number is required");
      } else if (!phoneNumberRegex.test(alternatephoneNumber)) {
        setAlternatePhoneError("Phone Number must contain only digits");
      } else if (alternatephoneNumber.length !== 10) {
        setAlternatePhoneError("Phone Number must have exactly 10 digits");
      } else {
        setAlternatePhoneError("");
      }
    }
  };

  const validateJobPosition = () => {
    if (!selectedJobPosition && jobPositionTouched) {
      setJobPositionError('Job Position is required');
    } else {
      setJobPositionError('');
    }
  };

  const validateJobPositionField = () => {
    if (!selectedJobPosition) {
      setJobPositionError('Job Position is required');
    } else {
      setJobPositionError('');
    }
  };

  const validateEmployeeId = (employeeIdGet) => {
    const employeeIdRegex = /^\d+$/;     //To check if the Employee Id contains only digits
    const maxEmployeeIdDigits = 5;
    const employee_id = employeeIdGet.toString();

    if (!employee_id.trim()) {
      setEmployeeIdError("Employee ID is required");
    } else if (!employeeIdRegex.test(employee_id)) {
      setEmployeeIdError("Employee ID must contain only numbers");
    } else if (employee_id.length > maxEmployeeIdDigits) {
      setEmployeeIdError(`Employee ID must have at most ${maxEmployeeIdDigits} digits`);
    } else {
      setEmployeeIdError("");
    }
  };

  // Department Validation
  const validateDepartment = () => {
    if (selectedDepartment === 'Select Department') {
      setDepartmentError('Please select Department');
    } else if(selectedDepartment === editedUser.department?.name){
      setSelectedDepartment(editedUser.department?.name.toUpperCase());
    } else {
      setDepartmentError('');
    }
  };

  const validateCountry = () => {
    if (selectedCountry === 'Select Country') {
      setCountryError('Please select Country');
    } else {
      setCountryError('');
    }
  };

  const validateGender = () => {
    if (selectedGender.trim() === editedUser.gender?.name) {
      setSelectedGender(editedUser.gender?.name.toUpperCase());
    } else if (selectedGender === '') {
      setSelectedGender(editedUser.gender?.name.toUpperCase());
    } else if (!['OTHERS', 'FEMALE', 'MALE'].includes(selectedGender)) {
      setGenderError('Invalid gender selected');
    } else {
      setGenderError('');
    }
  };

  //Firstname Validation
  const validateFirstName = (first_name) => {
    const nameRegex = /^[A-Za-z ]{1,20}$/; //To check the first name contains only letters and spaces, up to 20 characters

    if (!first_name.trim()) {
      setFirstNameError("Firstname is required");
    } else if (!nameRegex.test(first_name)) {
      setFirstNameError("Invalid first name. Use only letters and spaces, up to 20 characters.");
    } else {
      setFirstNameError("");
    }
  };

  //Lastname Validation
  const validateLastName = (last_name) => {
    const nameRegex = /^[A-Za-z ]{1,20}$/; //To check the Last name contains only letters and spaces, up to 20 characters

    if (!last_name.trim()) {
      setLastNameError("Lastname is required");
    } else if (!nameRegex.test(last_name)) {
      setLastNameError(
        "Invalid last name. Use only letters and spaces, up to 20 characters."
      );
    } else {
      setLastNameError("");
    }
  };

  // Role Validation
  const validateRole = () => {
    if (role === "Select Role") {
       setRoleError("Role is required");
    } else {
       setRoleError("");
    }
    if (isHRRole(role) && selectedRegions.length === 0) {
       setRegionError("Region is required for HR role");
    } else {
       setRegionError("");
    }
  };

  // Current Address Validation
  const validateCurrentAddress = (currentAddress) => {
    if (!currentAddress.trim()) {
      setCurrentAddressError("Current Address is required");
    } else {
      setCurrentAddressError("");
    }
  };

  // Permanent Address Validation
  const validatePermanentAddress = (permanentAddress) => {
    if (!permanentAddress.trim()) {
      setPermanentAddressError("Permanent Address is required");
    } else {
      setPermanentAddressError("");
    }
  };

 // Blood Group Validation
  const validateBloodGroup = () => {
    if (selectedBloodGroup === 'Select BloodGroup') {
      setBloodGroupError('Please select Blood Group');
    } else if (selectedBloodGroup === editedUser.personalDetails?.bloodGroup.id){
      setBloodGroup(editedUser.personalDetails?.bloodGroup.id);
    } else {
      setBloodGroupError('');
    }
  };

  // Family Contact Validation
  const validateFamilyContact = (familyContactGet) => {
    const familyContactRegex = /^\d+$/; //To check if the family conatct contains only digits
    const familyContact = familyContactGet.toString();
    if (!familyContact.trim()) {
      setFamilyContactError("Family Contact is required");
    } else if (!familyContactRegex.test(familyContact)) {
      setFamilyContactError("Family Contact must contain only digits");
    } else if (familyContact.length !== 10) {
      setFamilyContactError("Phone Number must have exactly 10 digits");
    } else {
      setFamilyContactError("");
    }
  };

  // Date of Birth Validation
  const validateDateOfBirth = (date_of_birth) => {
    if (!date_of_birth.trim()) {
      setDateOfBirthError("Date of Birth is required");
    } else {
      const today = new Date();
      const dob = new Date(date_of_birth);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      if (age < 18) {
        setDateOfBirthError("Age must be at least 18 years old");
      } else {
        setDateOfBirthError("");
      }
    }
  };

  // Aadhar Number Validation
  const validateAadhar = (aadharGet) => {
    const aadharRegex = /^\d{12}$/; //To check if the Aadhar number contains 12 digits
    const aadhar = String(aadharGet || '');
    if (selectedCountry && (selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL')) {
      (aadhar && !aadharRegex.test(aadhar)) ? setAadharError("Aadhar Number must contain 12 digits and no alphabets") : setAadharError("");
    } else if (selectedCountry && (selectedCountry === 'IN_CBE' || selectedCountry === 'IN_BGL')) {
      if (!aadhar.trim()) {
        setAadharError("Aadhar Number is required");
      } else if (!aadharRegex.test(aadhar)) {
        setAadharError("Aadhar Number must contain 12 digits and no alphabets");
      } else {
        setAadharError("");
      }
    }
  };

  // Pancard Number Validation
  const validatePancard = (pancard) => {
    const maxPancardLength = 12;
    const pancardRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;    //To check the Pancard number has the format ABCDE1234F
    if (selectedCountry && (selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL')) {
        (pancard && !pancardRegex.test(pancard)) ? setPancardError("Pancard Number must have the format ABCDE1234F") : setPancardError("");
    } else if ((selectedCountry && (selectedCountry === 'IN_CBE' || selectedCountry === 'IN_BGL')) && !pancard.trim()) {
        setPancardError("Pancard Number is required");
    } else if (pancard && !pancardRegex.test(pancard)) {
        setPancardError("Pancard Number must have the format ABCDE1234F");
    } else if (pancard && pancard.length > maxPancardLength){
        setPancardError(`Pancard Number must be at most ${maxPancardLength} characters`);
      } else {
      setPancardError("");
    }
  };

  // Passport Number Validation
  const validatePassport = (passport) => {
    const passportRegex = /^[A-Z0-9]+$/; // To check if the passport number has the format ABC1234567

    if ((selectedCountry && selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL') && !passport.trim()) {
      setPassportError("Passport Number is required");
    } else if (passport && !passportRegex.test(passport)) {
      setPassportError("Passport Number must have the format ABC1234567");
    } else {
      setPassportError("");
    }
  };

  const validateJoiningDate = (joiningDate) => {
    if (joiningDate == null) {
      setJoiningDateError("Joining Date is required");
    } else {
      setJoiningDateError("");
    }
  };

  const validatecurrentPostalCode = (currentPostalCode) => {
    if (!String(currentPostalCode).trim()) {
      setcurrentPostalCodeError("Postal Code is required");
    } else {
      setcurrentPostalCodeError("");
    }
  };

  const validatefamilyRelation = (familyRelation) => {
    if (!(familyRelation).trim()) {
      setfamilyRelationError("Family Relation is required");
    } else {
      setfamilyRelationError("");
    }
  };

   const validatefamilyRelationName = (familyRelationName) => {
     if (!(familyRelationName).trim()) {
       setfamilyRelationNameError("Emergency Number Name is required");
     } else {
       setfamilyRelationNameError("");
     }
   };

    const validatefamilyRelationAddress = (familyRelationAddress) => {
      if (!familyRelationAddress.trim()) {
        setfamilyRelationAddressError("Emergency Number Address is required");
      } else {
        setfamilyRelationAddressError("");
      }
    };

  const validatepermanentCountry = (permanentCountry) => {
    if (!permanentCountry.trim()) {
      setpermanentCountryError("country is required");
    } else {
      setpermanentCountryError("");
    }
  };

  const validateaddressLine2 = (addressLine2) => {
    if (!addressLine2.trim()) {
      setaddressLine2Error("Address Line2 is required");
    } else {
      setaddressLine2Error("");
    }
  };
  const validatemaritalStatus = () => {
    if (selectedMaritalStatus === 'Select Marital Status') {
      setmaritalStatusError('Please select Marital Status');
    } else if(selectedMaritalStatus === editedUser.maritalStatus?.name){
      setSelectedMaritalStatus(editedUser.department?.name.toUpperCase());
    } else {
      setmaritalStatusError('');
    }
  };

  const validatepermanentPostalCode = (permanentPostalCode) => {
    if (!String(permanentPostalCode).trim()) {
      setpermanentPostalCodeError("Postal Code is required");
    } else {
      setpermanentPostalCodeError("");
    }
  };

  const validatecurrentState = (currentState) => {
    if (!currentState.trim()) {
      setcurrentStateError("State is required");
    } else {
      setcurrentStateError("");
    }
  };

  const validatepermanentState = (permanentState) => {
    if (!permanentState.trim()) {
      setpermanentStateError("State is required");
    } else {
      setpermanentStateError("");
    }
  };

  const validatelastWorkingDate = (lastWorkingDate) => {
    if (!lastWorkingDate) {
      setlastWorkingDateError("Last Working Date is required");
    } else {
      setlastWorkingDateError("");
    }
  };

  const validateEmployeeStatus = () => {
    if (selectedEmployeeStatus === 'Select Employee Status') {
      setEmployeeStatusError('Please select Employee Status');
    } else if (selectedEmployeeStatus === editedUser.employeeStatus) {
      setEmployeeStatusError(editedUser.employeeStatus);
    } else {
      setEmployeeStatusError('');
    }
  };

  const validatePersonalEmail = (personalemail, officeemail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // It checks the email is valid or not
    // ^[^\s@]+@: Starts with one or more characters that are not whitespace or '@', then it is followed by @
    // [^\s@]+.: Followed by one or more characters that are not whitespace or '@', then it is followed by .
    // [^\s@]+$: Ends with one or more characters that are not whitespace or '@'
    if (personalemail && personalemail === officeemail) {
      setpersonalemailError("Personal email cannot be the same as office email");
    } else if (personalemail && !emailRegex.test(personalemail)) {
      setpersonalemailError("Invalid email address");
    } else {
      setpersonalemailError("");
    }
  };

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/usertypes`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const optionsArray = Object.keys(data).map((key) => {
            return {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            };
          });

          setUserTypes(optionsArray);
        } else {
          logger.error("Failed to fetch dropdown options");
        }
      } catch (error) {
        logger.error(
          "An error occurred while fetching dropdown options:",
          error
        );
      }
    };

    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    const fetchEmployeeStatusOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/employeestatus`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const employeeStatusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id
          }));
          setEmployeeStatus(employeeStatusArray);
        } else {
          logger.error('Failed to fetch employee status options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching employee status options:', error);
      }
    };
    fetchEmployeeStatusOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departmentResponse = await fetch(`${API_BASE_URL}/metadata/departments`, {
          method: 'POST',
          headers: getAuthHeaders(accessToken),
        });

        if (!departmentResponse.ok) {
          return;
        }

        const departmentData = await departmentResponse.json();
        const departmentsArray = Object.values(departmentData);
        if (Array.isArray(departmentsArray)) {
          setDepartment(departmentsArray);
        } else {
          logger.error("Department data is not a valid array:", departmentsArray);
        }
      } catch (error) {
        logger.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchBloodGroups = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/blood-groups`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const bloodGroupArray = Object.keys(data).map((key) => {
            return {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id
            };
          });
          setBloodGroup(bloodGroupArray);
        } else {
          logger.error('Failed to fetch BloodGroup options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching BloodGroup options:', error);
      }
    };

    fetchBloodGroups();
  }, []);

  useEffect(() => {
    const fetchGenderOptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/metadata/genders`,{
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
             ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          const genderArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id
          }));
          setGender(genderArray);
        } else {
          logger.error('Failed to fetch gender options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching gender options:', error);
      }
    };

    fetchGenderOptions();
  }, []);

   useEffect(() => {
          const fetchMaritalStatusOptions = async () => {
            try {
              const response = await fetch(`${API_BASE_URL}/metadata/maritalStatus`);
              if (response.ok) {
                const data = await response.json();
                const maritalStatusArray = Object.keys(data).map((key) => ({
                  name: data[key].name,
                  description: data[key].description,
                  enumValue: key,
                  id: data[key].id,
                }));
                setmaritalStatus(maritalStatusArray);
                console.log('Marital Status Options:', maritalStatusArray);
              } else {
                logger.error('Failed to fetch Marital Status options');
              }
            } catch (error) {
              logger.error('An error occurred while fetching Marital Status options:', error);
            }
          };
          fetchMaritalStatusOptions();
   }, [accessToken]);

  useEffect(() => {
    if (editedUser?.personalDetails) {
      setPassport(editedUser.personalDetails.passport || '');
      setPancard(editedUser.personalDetails.pancard || '');
      const aadharValue = String(editedUser.personalDetails.aadhar || '');
      setAadhar(aadharValue);
    }
  }, [editedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    if (name === 'passport') {
      setPassport(value);
    } else if (name === 'aadhar') {
      setAadhar(value);
    } else if (name === 'pancard') {
      setPancard(value);
    }
    if (name.startsWith('personalDetails.')) {
      setEditedUser((prevUser) => ({
        ...prevUser,
        personalDetails: {
          ...prevUser.personalDetails,
          [name.split('.')[1]]: value,
        },
      }));
    } else {
      setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const passportRequired = selectedCountry && selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL';
    const isAadharPancardRequired = selectedCountry && (selectedCountry === 'IN_CBE' || selectedCountry === 'IN_BGL');
    const trimmedLastWorkingDate = lastWorkingDate.trim();
    const isLastWorkingDateInvalid = selectedEmployeeStatus === 'EX_EMPLOYEE' && !trimmedLastWorkingDate;

    // Call validation methods
    validateEmail(editedUser.email);
    validatePersonalEmail(editedUser.personalDetails?.personalemail, editedUser.email);
    validateUsername(editedUser.username);
    validatePhoneNumber(editedUser.phoneNumber);
    validateJobPosition(editedUser.jobPosition);
    validateEmployeeId(editedUser.employeeId);
    validateFirstName(editedUser.firstName);
    validateLastName(editedUser.lastName);
    validateDateOfBirth(editedUser.personalDetails?.date_of_birth);
    validateCurrentAddress(editedUser.personalDetails?.currentAddress);
    validatePermanentAddress(editedUser.personalDetails?.permanentAddress);
    validateBloodGroup(editedUser.personalDetails?.bloodGroup);
    validateFamilyContact(editedUser.personalDetails?.familyContact);
    validateCountry();
    validatePancard(editedUser.personalDetails?.pancard);
    validateAadhar(editedUser.personalDetails?.aadhar);
    validatePassport(editedUser.personalDetails?.passport);
    validateAlternatePhoneNumber(editedUser.personalDetails?.alternatephoneNumber);
    validatelastWorkingDate(lastWorkingDate);
    validateaddressLine2(editedUser.personalDetails?.addressLine2);
    validatefamilyRelation(editedUser.personalDetails?.familyRelation);
    validatepermanentCountry(editedUser.personalDetails?.permanentCountry);
    validatepermanentState(editedUser.personalDetails?.permanentState);
    validatepermanentPostalCode(editedUser.personalDetails?.permanentPostalCode);
    validatecurrentState(editedUser.personalDetails?.currentState);
    validatecurrentPostalCode(editedUser.personalDetails?.currentPostalCode);
    validatefamilyRelationName(editedUser.personalDetails?.familyRelationName);
    validatefamilyRelationAddress(editedUser.personalDetails?.familyRelationAddress);
    if (
      (passportRequired && !passport.trim()) ||
      (isAadharPancardRequired && (!aadhar.trim() || !pancard.trim())) ||
      emailError ||
      personalemailError ||
      usernameError ||
      phoneError ||
      jobPositionError ||
      employeeIdError ||
      departmentError ||
      genderError ||
      first_name_error ||
      last_name_error ||
      roleError ||
      dateOfBirthError ||
      currentAddressError ||
      permanentAddressError ||
      bloodGroupError ||
      familyContactError ||
      countryError ||
      pancardError ||
      aadharError ||
      passportError ||
      joiningDateError ||
      employeeStatusError ||
      currentStateError ||
      currentPostalCodeError ||
      permanentStateError ||
      permanentPostalCodeError ||
      permanentCountryError ||
      familyRelationError ||
      addressLine2Error ||
      isLastWorkingDateInvalid ||
      lastWorkingDateError ||
      regionError ||
      familyRelationNameError ||
      familyRelationAddressError ||
      maritalStatusError
    ) {
      setErrorMessage("Please correct the highlighted fields before updating.");
      return;
    }

    const genderToUpdate = selectedGender.trim() === '' ? editedUser.gender?.name.toUpperCase() : selectedGender;
    const departmentToUpdate = selectedDepartment.trim() === '' ? editedUser.department?.name.toUpperCase() : selectedDepartment;
    const roleToUpdate = role.trim() === '' ? editedUser.role?.name.toUpperCase() : role;
    const countryToUpdate = selectedCountry === '' ? editedUser.personalDetails?.country.id : selectedCountry;
    const bloodGroupToUpdate = selectedBloodGroup === '' ? editedUser.personalDetails?.bloodGroup.id : selectedBloodGroup;
    const maritalStatusToUpdate = selectedMaritalStatus.trim() === '' ? editedUser.maritalStatus?.name.toUpperCase() : selectedMaritalStatus;
    const response = await fetch(`${API_BASE_URL}/users/${editedUser.id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...getAuthHeaders(accessToken),
      },
      body: JSON.stringify({
        id: editedUser.id,
        employeeId: editedUser.employeeId,
        email: editedUser.email,
        username: editedUser.username,
        firstName: editedUser.firstName,
        lastName: editedUser.lastName,
        phoneNumber: editedUser.phoneNumber,
        gender: genderToUpdate,
        role: roleToUpdate,
        department: departmentToUpdate,
        jobPosition: selectedJobPosition,
        manager: selectedManagerId,
        employeeStatus: selectedEmployeeStatus,
        lastWorkingDate: lastWorkingDate,
        personalDetails: {
          date_of_birth: editedUser.personalDetails?.date_of_birth,
          alternatephoneNumber: editedUser.personalDetails?.alternatephoneNumber,
          personalemail: editedUser.personalDetails?.personalemail,
          currentAddress: editedUser.personalDetails?.currentAddress,
          permanentAddress: editedUser.personalDetails?.permanentAddress,
          bloodGroup: bloodGroupToUpdate,
          familyContact: editedUser.personalDetails?.familyContact,
          country: countryToUpdate,
          passport: editedUser.personalDetails?.passport,
          pancard: editedUser.personalDetails?.pancard,
          aadhar: editedUser.personalDetails?.aadhar,
          joiningDate: editedUser.personalDetails?.joiningDate,
          currentState: editedUser.personalDetails?.currentState,
          currentPostalCode: editedUser.personalDetails?.currentPostalCode,
          permanentState: editedUser.personalDetails?.permanentState,
          permanentPostalCode: editedUser.personalDetails?.permanentPostalCode,
          permanentCountry: editedUser.personalDetails?.permanentCountry,
          familyRelation: editedUser.personalDetails?.familyRelation,
          addressLine2: editedUser.personalDetails?.addressLine2,
          familyRelationName: editedUser.personalDetails?.familyRelationName,
          familyRelationAddress: editedUser.personalDetails?.familyRelationAddress,
          maritalStatus: maritalStatusToUpdate,
        },
        regionAccessByRoles: {
          countriesId: selectedRegions,
        }
      }),
    });

    if (response.ok) {
      updateProfilePhoto();
      const result = await response.json();
      onSave && onSave(editedUser);
      setUpdateSuccess(true);
      setErrorMessage("");
      setTimeout(() => {
        setUpdateSuccess(false);
        onSave && onSave(editedUser);
        navigate('/home');
      }, 2000);
    } else {
      const errorResult = await response.json();
      setErrorMessage(errorResult.description);
      logger.error(errorResult.description);
    }
  };

  // Fetch user details from the API
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const userProfileResponse = await fetch(`${API_BASE_URL}/user/${USERID}`, {
          method: "POST",
          headers: {
            [UserHeaders.USERNAME.headerKey]: UserHeaders.USERNAME.value,
            ...getAuthHeaders(accessToken),
          },
        });

        if (!userProfileResponse.ok) {
          logger.warn("User profile response not OK:", userProfileResponse);
          return;
        }

        const data = await userProfileResponse.json();
        const userProfileData = data.message;

        setEditedUser(userProfileData);

        if (userProfileData && typeof userProfileData === "object") {
          setEditedUser(userProfileData);
        } else {
          logger.error(
            "User profile data is not a valid object:",
            userProfileData
          );
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };
    fetchUserDetails();
  }, [accessToken]);

  const fetchManager = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const usersInDepartmentResponse = await fetch(`${API_BASE_URL}/users-in-department?department=${department}`,
        {
          method: 'POST',
          headers: {
            ...getAuthHeaders(accessToken),
          },
        }
      );
      const responseData = await usersInDepartmentResponse.json();
      const usersInDepartmentData = responseData.message;

      if (Array.isArray(usersInDepartmentData)) {
        setManager(usersInDepartmentData);
        setManagerError('');
      } else if (typeof usersInDepartmentData === 'object') {
        // Convert the object to an array of users
        const usersArray = Object.keys(usersInDepartmentData)
          .filter((username) => username !== editedUser.username)
          .map((username) => ({
            id: usersInDepartmentData[username],
            username: username,
          }));

        setManager(usersArray);
        setManagerError('');
      } else {
        logger.error('Invalid data format received:', usersInDepartmentData);
        setManagerError('Invalid data format received');
      }
    } catch (error) {
      logger.error('An error occurred while fetching users in the department:', error);
      setManagerError('An error occurred while fetching users in the department');
    }
  };

  const handleDepartmentChange = async (e) => {
    const selectedDepartment = e.target.value.toUpperCase();
    logger.info('SELECTED DEPARTMENT', selectedDepartment);
    setSelectedDepartment(selectedDepartment);
    setSelectedManagerId(null);
    fetchManager(selectedDepartment);
    await fetchJobPositions(selectedDepartment);
    setJobPositionTouched(true);
    validateJobPositionField();
  };

  const handleBloodGroupChange = (e) => {
    const selectedBloodGroup = e.target.value;
    setSelectedBloodGroup(selectedBloodGroup);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    updateReadOnlyFields(selectedCountry);
  };

  const updateReadOnlyFields = (country) => {
    const aadharValue = editedUser.personalDetails?.aadhar;
    const pancardValue = editedUser.personalDetails?.pancard;

    if (
      (country === 'IN_CBE' ||
        country === 'IN_BGL' ||
        country === 'Coimbatore' ||
        country === 'Bangalore') &&
      (aadharValue || pancardValue)
    ) {
      setIsAadharReadOnly(!!aadharValue);
      setIsPancardReadOnly(!!pancardValue);
    } else {
      setIsAadharReadOnly(false);
      setIsPancardReadOnly(false);
    }
  };

  const handleEmployeeStatusChange = (e) => {
    const selectedEmployeeStatus = e.target.value;
    logger.info("SELECTED EMPLOYEE STATUS", selectedEmployeeStatus);
    setSelectedEmployeeStatus(selectedEmployeeStatus);
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const updateProfilePhoto = async (e) => {
    // e.preventDefault();
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("profilephoto", file);

    try {
      const response = await fetch(
        `${API_BASE_URL}/uploadprofilephoto/${USERID}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // window.location.reload();
        setUpdateSuccess(true); // Set update success state to true
        setTimeout(() => {
          setUpdateSuccess(false); // Hide success message after 2 seconds
        }, 2000);
      } else {
        logger.error("Failed to upload Profile Image");
      }
    } catch (error) {
      logger.error("Error during fetch operation: ", error);
    }
  };

  useEffect(() => {
    if (editedUser.department?.id) {
      const departmentName = editedUser.department.name
        ? editedUser.department.name.toUpperCase()
        : '';
      fetchManager(departmentName);
    }
    if (editedUser.departmentHierarchy?.manager) {
      setSelectedManagerId(editedUser.departmentHierarchy.manager.id);
    } else {
      setSelectedManagerId(null);
    }
    if (editedUser.employeeStatus) {
      setSelectedEmployeeStatus(editedUser.employeeStatus.name.toUpperCase());
    }
  }, [
    editedUser.department?.id,
    editedUser.department?.name,
    editedUser.employeeStatus,
    editedUser.departmentHierarchy?.manager,
  ]);

  const { userId, previousPagePath } = location.state || {};

  const backButtonHandler = () => {
    if (previousPagePath === "/profile") {
      navigate('/profile');
    } else if (previousPagePath && previousPagePath.startsWith("/profile/")) {
      navigate(previousPagePath);
    } else if (
      editedUser.employeeStatus.name === "Permanent" ||
      editedUser.employeeStatus.name === "Probation"
    ) {
      navigate('/allusers');
    } else {
      navigate('/exemployees');
    }
  };

  const shouldDisplayAsterisk = () => {
    if (
      editedUser.personalDetails?.country.name === 'Coimbatore' ||
      editedUser.personalDetails?.country.name === 'Bangalore'
    ) {
      return !(selectedCountry && selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL');
    } else {
      return selectedCountry && (selectedCountry === 'IN_CBE' || selectedCountry === 'IN_BGL');
    }
  };

  const shouldDisplayAsteriskPassport = () => {
    if (
      editedUser.personalDetails?.country.name !== 'Coimbatore' &&
      editedUser.personalDetails?.country.name !== 'Bangalore'
    ) {
      return !(selectedCountry && (selectedCountry === 'IN_CBE' || selectedCountry === 'IN_BGL'));
    } else {
      return selectedCountry && selectedCountry !== 'IN_CBE' && selectedCountry !== 'IN_BGL';
    }
  };

  useEffect(() => {
    if (!hasRun && editedUser?.role?.name) {
      setHasRun(true);
      if (editedUser.role.name === 'HR') {
        setShowRegionSelection(true);
        setSelectedRegions(editedUser.regionAccessByRoles?.countriesId || []);
      } else {
        setShowRegionSelection(false);
      }
    }
  }, [editedUser, hasRun]);

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    if (selectedRole === 'HR') {
      setShowRegionSelection(true);
      setSelectedRegions(editedUser.regionAccessByRoles?.countriesId || []);
    } else {
      setShowRegionSelection(false);
      setSelectedRegions([]);
      setRegionError("");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    if (value === 'all') {
      if (selectedRegions.length === countries.length) {
        setSelectedRegions([]);
      } else {
        setSelectedRegions(countries.map((country) => country.id));
      }
    } else {
      const numericValue = parseInt(value, 10);
      setSelectedRegions((prevSelectedRegions) => {
        if (prevSelectedRegions.includes(numericValue)) {
          return prevSelectedRegions.filter((region) => region !== numericValue);
        } else {
          return [...prevSelectedRegions, numericValue];
        }
      });
    }
  };

  const selectedRegionsLabels = countries
    .filter((country) => selectedRegions.includes(country.id)) // Assuming country.id is the identifier
    .map((country) => country.name)
    .join(', ');

  const handleOutsideClick = (event) => {
    // Check if the clicked element is inside the dropdown container
    if (!event.target.closest('.region-select-container')) {
      setDropdownOpen(false);
    }
  };
  document.body.addEventListener('click', handleOutsideClick);

  const fetchJobPositions = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/metadata/jobpositions?department=${department}`,{
         method: 'POST',
         headers: {
            ...getAuthHeaders(accessToken),
         },
      });
      if (response.ok) {
        const data = await response.json();
        const jobPositionArray = Object.keys(data.message).map((key) => {
          return {
            name: data.message[key].name,
            description: data.message[key].description,
            enumValue: key,
            department: data.message[key].department,
            id: data.message[key].id,
          };
        });
        setJobPositions(jobPositionArray);
        const matchingJobPosition = jobPositionArray.find(
          (position) => position.name === editedUser.jobPosition?.name
        );
        setSelectedJobPosition(matchingJobPosition ? matchingJobPosition.enumValue : '');
      } else {
        logger.error('Failed to fetch job positions');
      }
    } catch (error) {
      logger.error('An error occurred while fetching job positions:', error);
    }
  };

  useEffect(() => {
    if (editedUser.department?.id) {
      const departmentName = editedUser.department.name
        ? editedUser.department.name.toUpperCase()
        : '';
      fetchJobPositions(departmentName);
    }
  }, [editedUser.department?.id, editedUser.department?.name]);

  const handleJobPositionChange = (e) => {
    const value = e.target.value;
    setSelectedJobPosition(value);
    handleInputChange({ target: { name: 'jobPosition', value } });
    setJobPositionTouched(true);
    setJobPositionError('');
  };

  return (
    <div>
      <div className="container">
        <form onSubmit={handleUpdate} className="edit-form">
          <div className="row mt-1">
            <div className="edit-profile">
              <div className="edit-employee-info">
                <img
                  src={editedUser.personalDetails?.profile_photo ? editedUser.personalDetails.profile_photo : "./profileshadow.jpg"}
                  className="edit-profile-photo"
                />
                <div className="name-job-department">
                  <span className="name-element">{editedUser.username}</span>
                  <div className="job-department">
                    <span className="job-position">{editedUser.jobPosition?.name},</span>
                    <span className="department">{editedUser.department?.name}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit user input fields */}
            <div className="row">
              <div className="col-md-6 edit-margin">
                <div className="input-field">
                  <div className="row text-start">
                    <strong>Employee ID <span className="span-style" >*</span></strong>{" "}
                  </div>
                  <div className="row text-start">
                    <input type="text" className="textbox" name="employeeId" id="employeeId" value={editedUser.employeeId} onBlur={() => validateEmployeeId(editedUser.employeeId)} onChange={handleInputChange} readOnly/>
                    {employeeIdError && ( <p className="error-message"> {employeeIdError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Employee Name <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="username" id="username" value={editedUser.username} onBlur={() => validateUsername(editedUser.username)} onChange={handleInputChange} readOnly />
                    {usernameError && (<p className="error-message">{usernameError}</p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Date Of Birth <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="date" className="textbox" name="personalDetails.date_of_birth" id="date_of_birth" value={editedUser.personalDetails?.date_of_birth} onBlur={() => validateDateOfBirth( editedUser.personalDetails?.date_of_birth)} onChange={handleInputChange} max={new Date().toISOString().split('T')[0]} readOnly />
                     {dateOfBirthError && ( <p className="error-message"> {dateOfBirthError}</p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>First Name <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="firstName" id="firstName" value={editedUser.firstName} onBlur={() => validateFirstName(editedUser.firstName)} onChange={handleInputChange}/>
                    {first_name_error && ( <p  className="error-message"> {first_name_error} </p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Last Name <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="lastName" id="lastName" value={editedUser.lastName} onBlur={() => validateLastName(editedUser.lastName)} onChange={handleInputChange} />
                    {last_name_error && (<p  className="error-message"> {last_name_error}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong> Company Email <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="email" id="email" value={editedUser.email} onBlur={() => validateEmail(editedUser.email)} onChange={handleInputChange} readOnly/>
                    {emailError && (<p  className="error-message"> {emailError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Alternate MobileNo</strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.alternatephoneNumber" id="alternatephoneNumber" value={editedUser.personalDetails?.alternatephoneNumber} onBlur={() => validateAlternatePhoneNumber(editedUser.personalDetails?.alternatephoneNumber)} onChange={handleInputChange} />
                    {alternatephoneError && (<p className="error-message">{alternatephoneError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Gender <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select id="gender" name="gender" value={selectedGender} onBlur={() => validateGender()} onChange={(e) => setSelectedGender(e.target.value)} className="textbox">
                      <option value={editedUser.gender?.name.toUpperCase()}> {editedUser.gender?.name} </option>
                      {gender.filter(option => option.name !== editedUser.gender?.name).map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {genderError && ( <p className="error-message"> {genderError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Personal Email <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.personalemail" id="personalemail" value={editedUser.personalDetails?.personalemail} onBlur={() => validatePersonalEmail(editedUser.personalDetails?.personalemail, editedUser.email)} onChange={handleInputChange} />
                    {personalemailError && ( <p className="error-message"> {personalemailError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Mobile Number <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="phoneNumber" id="phoneNumber" value={editedUser.phoneNumber} onBlur={() => validatePhoneNumber(editedUser.phoneNumber)} onChange={handleInputChange} />
                    {phoneError && ( <p className="error-message"> {phoneError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Role <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select value={role} onBlur={() => validateRole(role)} onChange={handleRoleChange} id="role" name="role" className="textbox select-box">
                      <option value="" disabled hidden>
                        {role ? "Select role" : editedUser.role?.name}
                      </option>
                      {userTypes.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {roleError && (<p className="error-message"> {roleError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Region {isHRRole(role) && <span className="text-danger">*</span>}</strong>{" "}
                  </div>
                  <div className="region-select-container" onBlur={() => validateRole(role)}>
                    <div
                      className={`region-select-header ${role === 'HR' ? 'pointer-cursor' : 'default-cursor'}`}
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                      onBlur={() => validateRole(role)}
                    >
                      <div className="region-select-text-wrapper">
                        <span className={`region-select-text ${selectedRegions.length === 0 ? 'input-placeholder' : ''}`}>
                          {selectedRegions.length > 0 ? selectedRegionsLabels : 'Select Region'}
                        </span>
                      </div>
                    </div>
                    {showRegionSelection ? (
                      dropdownOpen && (
                        <div className="region-select-dropdown">
                          <div key="all" className="custom-select-option">
                            <input
                              type="checkbox"
                              id="checkbox-all"
                              value="all"
                              checked={selectedRegions.length === countries.length}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="checkbox-all">Select All</label>
                          </div>
                          {countries.map((option) => (
                            <div key={option.id} className="custom-select-option">
                              <input
                                type="checkbox"
                                id={`checkbox-${option.id}`}
                                value={option.id}
                                checked={selectedRegions.includes(option.id)}
                                onChange={handleCheckboxChange}
                              />
                              <label htmlFor={`checkbox-${option.id}`}>{option.name}</label>
                            </div>
                          ))}
                        </div>
                      )
                    ) : null}
                    {regionError && (<p className="error-message"> {regionError} </p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Joining Date <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="date" id="joiningDate" name="personalDetails.joiningDate" value={editedUser.personalDetails?.joiningDate} onBlur={() => validateJoiningDate(editedUser.personalDetails?.joiningDate)} onChange={handleInputChange} className="textbox" readOnly/>
                    {joiningDateError && <p className="error-message">{joiningDateError}</p>}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Employee Status <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select value={selectedEmployeeStatus} onBlur={() => validateEmployeeStatus()} onChange={handleEmployeeStatusChange} id="role" name="role" className="textbox select-box" >
                      <option value="" disabled hidden>{selectedEmployeeStatus ? "Select Employee Status" : editedUser.personalDetails.employeeStatus?.name}</option>
                      {employeeStatus.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {employeeStatusError && <p className="error-message">{employeeStatusError}</p>}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>HouseNo/Street/Landmark <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.permanentAddress" id="permanentAddress" value={editedUser.personalDetails?.permanentAddress} onBlur={() => validatePermanentAddress(editedUser.personalDetails?.permanentAddress)} onChange={handleInputChange} />
                    {permanentAddressError && (<p className="error-message"> {permanentAddressError}</p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                     <strong>Permanent country <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.permanentCountry" id="permanentCountry" value={editedUser.personalDetails?.permanentCountry} onBlur={() => validatepermanentCountry(editedUser.personalDetails?.permanentCountry)} onChange={handleInputChange} />
                    {permanentCountryError && (<p className="error-message"> {permanentCountryError}</p> )}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                     <strong>Permanent State <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                     <input type="text" className="textbox" name="personalDetails.permanentState" id="permanentState" value={editedUser.personalDetails?.permanentState} onBlur={() => validatepermanentState(editedUser.personalDetails?.permanentState)} onChange={handleInputChange} />
                     {permanentStateError && (<p  className="error-message"> {permanentStateError}</p> )}
                  </div>
                </div>
                <div className="input-field">
                   <div className="row text-start">
                     <strong>Permanent Postal Code <span className="span-style">*</span></strong>{" "}
                   </div>
                   <div className="row">
                     <input type="text" className="textbox" name="personalDetails.permanentPostalCode" id="permanentPostalCode" value={editedUser.personalDetails?.permanentPostalCode} onBlur={() => validatepermanentPostalCode(editedUser.personalDetails?.permanentPostalCode)} onChange={handleInputChange} />
                     {permanentPostalCodeError && (<p className="error-message"> {permanentPostalCodeError}</p>)}
                   </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-field">
                  <div className="row text-start">
                    <strong>Address Line1 <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.currentAddress" id="currentAddress" value={editedUser.personalDetails?.currentAddress} onBlur={() => validateCurrentAddress(editedUser.personalDetails?.currentAddress)} onChange={handleInputChange} />
                    {currentAddressError && (<p className="error-message">{currentAddressError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Address Line2 <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.addressLine2" id="addressLine2" value={editedUser.personalDetails?.addressLine2} onBlur={() => validateaddressLine2(editedUser.personalDetails?.addressLine2)} onChange={handleInputChange} />
                    {addressLine2Error && (<p className="error-message">{addressLine2Error}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Current State <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.currentState" id="currentState" value={editedUser.personalDetails?.currentState} onBlur={() => validatecurrentState(editedUser.personalDetails?.currentState)} onChange={handleInputChange} />
                    {currentStateError && (<p className="error-message">{currentStateError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Current Postal Code <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.currentPostalCode" id="currentPostalCode" value={editedUser.personalDetails?.currentPostalCode} onBlur={() => validatecurrentPostalCode(editedUser.personalDetails?.currentPostalCode)} onChange={handleInputChange} />
                    {currentPostalCodeError && (<p className="error-message">{currentPostalCodeError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Department <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select id="officialDepartment" name="officialDepartment" value={selectedDepartment || ''} onBlur={() => validateDepartment()} onChange={handleDepartmentChange} className="textbox department-border">
                      <option value="" disabled hidden>
                        {selectedDepartment ? "Select Department" : editedUser.department?.name}
                      </option>
                      {department.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {departmentError && (<p className="error-message">{departmentError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Job Position <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select value={selectedJobPosition} onBlur={validateJobPosition} onChange={handleJobPositionChange} id="jobPosition" name="jobPosition" className="textbox select-box" >
                      <option value="" disabled hidden>
                        {selectedJobPosition ? "Select Job Position" : editedUser.jobPosition?.name || 'Select Job Position'}
                      </option>
                      {jobPositions.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {jobPositionError && <p className="error-message">{jobPositionError}</p>}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Manager </strong>{" "}
                  </div>
                  <div className="row">
                    <select id="officialManager" name="officialManager" value={selectedManagerId || ""} onBlur={() => validateDepartment()} onChange={(e) => {const value = e.target.value || null; setSelectedManagerId(value); handleUsernameSelection(value);}} className="textbox department-border" >
                      <option value="">Select Manager (Optional)</option>
                      {manager.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                    {managerError && (<p className="error-message">{managerError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Blood Group <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select id="bloodGroup" className="textbox" name="personalDetails.bloodGroup" value={selectedBloodGroup} onBlur={() => validateBloodGroup()} onChange={handleBloodGroupChange} >
                      <option value="" disabled hidden>
                        {selectedBloodGroup ? "Select bloodGroup" : editedUser.personalDetails.bloodGroup?.name}
                      </option>
                      {bloodGroup.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {bloodGroupError && (<p className="error-message">{bloodGroupError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Emergency Number <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.familyContact" id="familyContact" value={editedUser.personalDetails?.familyContact} onBlur={() => validateFamilyContact(editedUser.personalDetails?.familyContact)} onChange={handleInputChange} />
                    {familyContactError && (<p className="error-message">{familyContactError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                   <div className="row text-start">
                     <strong>Emergency Number Name <span className="span-style">*</span></strong>{" "}
                   </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.familyRelationName" id="familyRelationName" value={editedUser.personalDetails?.familyRelationName} onBlur={() => validatefamilyRelationName(editedUser.personalDetails?.familyRelationName)} onChange={handleInputChange} />
                     {familyRelationNameError && (<p className="error-message">{familyRelationNameError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Emergency Number Relation <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.familyRelation" id="familyRelation" value={editedUser.personalDetails?.familyRelation} onBlur={() => validatefamilyRelation(editedUser.personalDetails?.familyRelation)} onChange={handleInputChange} />
                    {familyRelationError && (<p className="error-message">{familyRelationError}</p>)}
                  </div>
                </div>
                <div className="input-field">
                   <div className="row text-start">
                     <strong>Emergency Number Address <span className="span-style">*</span></strong>{" "}
                   </div>
                 <div className="row">
                   <input type="text" className="textbox" name="personalDetails.familyRelationAddress" id="familyRelationAddress" value={editedUser.personalDetails?.familyRelationAddress} onBlur={() => validatefamilyRelationAddress(editedUser.personalDetails?.familyRelationAddress)} onChange={handleInputChange} />
                   {familyRelationAddressError && (<p className="error-message">{familyRelationAddressError}</p>)}
                 </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Work Location <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <select id="country" name="personalDetails.country" value={selectedCountry} onBlur={() => validateCountry()} onChange={handleCountryChange} className="textbox">
                      <option value="" disabled hidden>
                        {selectedCountry ? "Select country" : editedUser.personalDetails.country?.name}
                      </option>
                      {countries.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))}
                    </select>
                    {countryError && (<p className="error-message">{countryError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Passport Number {shouldDisplayAsteriskPassport() && <span className="span-style">*</span>}</strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.passport" id="passport" value={editedUser.personalDetails?.passport} onBlur={() => validatePassport(editedUser.personalDetails?.passport)} onChange={handleInputChange}  readOnly={editedUser.personalDetails?.country.name==='Dubai'}/>
                    {passportError && (<p className="error-message">{passportError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Pancard Number {shouldDisplayAsterisk() && <span className="span-style">*</span>}</strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.pancard" id="pancard" value={editedUser.personalDetails?.pancard} onBlur={() => validatePancard(editedUser.personalDetails?.pancard)} onChange={handleInputChange}  readOnly={isPancardReadOnly}/>
                    {pancardError && (<p className="error-message">{pancardError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Aadhar Number {shouldDisplayAsterisk() && <span className="span-style">*</span>}</strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="personalDetails.aadhar" id="aadhar" value={editedUser.personalDetails?.aadhar} onBlur={() => validateAadhar(editedUser.personalDetails?.aadhar)} onChange={handleInputChange}  readOnly={isAadharReadOnly}/>
                    {aadharError && (<p className="error-message">{aadharError}</p>)}
                  </div>
                </div>

                <div className="input-field">
                  <div className="row text-start">
                    <strong>Profile Photo</strong>{" "}
                  </div>
                  <div className="row">
                    <input type="file" name="profile_photo" id="profile_photo" className="textbox" onChange={handleFileUpload} />
                  </div>
                </div>
                <div className="input-field">
                  <div className="row text-start">
                     <strong>Marital Status<span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                     <select id="maritalStatus" name="maritalStatus" value={selectedMaritalStatus} onBlur={() => validatemaritalStatus()} onChange={(e) => setSelectedMaritalStatus(e.target.value)} className="textbox">
                     <option value={editedUser.personalDetails.maritalStatus?.name.toUpperCase()}> {editedUser.personalDetails.maritalStatus?.name} </option>
                     {maritalStatus.filter(option => option.name !== editedUser.personalDetails.maritalStatus?.name).map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                     ))}
                     </select>
                       {maritalStatusError && (<p className="error-message">{maritalStatusError}</p>)}
                  </div>
                </div>
                {selectedEmployeeStatus === 'EX_EMPLOYEE' && (
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Last Working Date</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="date" id="lastWorkingDate" name="lastWorkingDate" value={lastWorkingDate} onBlur={() => validatelastWorkingDate(lastWorkingDate)} onChange={(e) => setLastWorkingDate(e.target.value)} className="textbox"/>
                      {lastWorkingDateError && <p className="error-message">{lastWorkingDateError}</p>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center custom-bottom ms-1">
            <div className="col-auto">
              <button type="button" className="edit-button" onClick={() => backButtonHandler()} >
                Back
              </button>
            </div>
            <div className="col-auto ms-5">
              <button type="submit" className="edit-button">
                Save
              </button>
            </div>
            <div className="row justify-content-center mb-1">
              <div>
                {updateSuccess && (
                  <div className="edit-success">Updated successfully!</div>
                )}
                {errorMessage && (
                  <div class="edit-error">{errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserPage;
