import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import logger from "./logs/logger";
import "./styles/ProfilePage.css";
import { useNavigate } from "react-router-dom";
import "./styles/Chart.css";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';

const LeaveReportChart = () => {
  const [leaveReport, setLeaveReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const userRole = UserHeaders.USERROLE.value;

  useEffect(() => {
    const fetchLeaveReport = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        if (!userRole) {
          logger.error("User role not available");
          return;
        }

        const url = `${API_BASE_URL}/used-leave/${UserHeaders.USERID.value}`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            [UserHeaders.USERNAME.headerKey]: UserHeaders.USERNAME.value,
            [UserHeaders.USERROLE.headerKey]: UserHeaders.USERROLE.value,
            ...getAuthHeaders(accessToken),
          },
        });
        const data = await response.json();
         logger.info("Fetched leave report data:", data);

        if (data && data.message) {
          setLeaveReport([data.message]);
        } else {
          logger.error("Data received is not valid:", data);
        }
        setIsLoading(false);
      } catch (error) {
        logger.error("Error fetching leave report:", error);
        setIsLoading(false);
      }
    };
    fetchLeaveReport();
  }, [userRole, accessToken, navigate]);

  useEffect(() => {
    if (!isLoading && leaveReport.length > 0) {
      leaveReport.forEach((item, index) => {
        const casualLeaveCanvas = document.getElementById(`casualLeaveChart${index}`);
        const sickLeaveCanvas = document.getElementById(`sickLeaveChart${index}`);
        const annualLeaveCanvas = document.getElementById(`annualLeaveChart${index}`);
        const earnedLeaveCanvas = document.getElementById(`earnedLeaveChart${index}`);
        const sickLeaveChartCanvas = document.getElementById(`sickleavechart${index}`);

        if (casualLeaveCanvas) destroyChart(casualLeaveCanvas.id);
        if (sickLeaveCanvas) destroyChart(sickLeaveCanvas.id);
        if (annualLeaveCanvas) destroyChart(annualLeaveCanvas.id);
        if (earnedLeaveCanvas) destroyChart(earnedLeaveCanvas.id);
        if (sickLeaveChartCanvas) destroyChart(sickLeaveChartCanvas.id);

        // Check if country is India
        if (UserHeaders.COUNTRY.value === "IN_BGL" || UserHeaders.COUNTRY.value === "IN_CBE") {
          logger.info("Creating charts for India");
          if (casualLeaveCanvas) createPieChart(casualLeaveCanvas.id, item.remainingCasualLeave || 0, item.usedCasualLeave, "Casual");
          if (sickLeaveCanvas) createPieChart(sickLeaveCanvas.id, item.remainingSickLeave || 0, item.usedSickLeave, "Sick");
          if (annualLeaveCanvas) createPieChart(annualLeaveCanvas.id, item.remainingAnnualLeave || 0, item.usedAnnualLeave, "Annual");
        } else {
           logger.info("Creating charts for non-India");
          // Render only earned leave and sick leave charts
          // Render only earned leave and sick leave charts
          if (earnedLeaveCanvas) createPieChart(earnedLeaveCanvas.id, item.remainingEarnedLeave || 0, item.usedEarnedLeave, "Earned");
          if (sickLeaveChartCanvas) createPieChart(sickLeaveChartCanvas.id, item.remainingSick_leave || 0, item.usedSick_leave, "Sick");
        }
      });
    }
  }, [leaveReport, isLoading]);

  const createPieChart = (canvasId, remaining, used, leaveType) => {
     logger.info(`Creating chart for ${leaveType} with ID: ${canvasId}`);
    const ctx = document.getElementById(canvasId);
    if (!ctx) {
      logger.error(`Canvas with ID ${canvasId} not found`);
      return;
    }
    const context = ctx.getContext("2d");
    ctx.width = 420;
    ctx.height = 300;
    const globalBackgroundColor = ["#242D67", "#D3BDFF"];
    const borderColor = "#fff";

    const data = {
      labels: ["Remaining", "Used"],
      datasets: [
        {
          data: [remaining, used],
          backgroundColor: globalBackgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontColor: "#0f0f0f",
            fontFamily: "Montserrat",
            usePointStyle: true,
            padding: 20,
            boxWidth: 20,

          },
           elements: {
                  arc: {
                    borderRadius: 25,
                  },
                },
        },
        datalabels: {
              color: function(context) {
                var index = context.dataIndex;
                return index === 0 ? '#FFFFFF' : '#000000';
              },
              textAlign: "center",
              font: {
                size: "24px",
                fontFamily: "Montserrat",
                weight: "600",
                lineHeight: "29.26px"
              },
              formatter: function (value) {
                return value;
              },
            },
          },
        };

    new Chart(context, {
      type: "pie",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  };

  const destroyChart = (canvasId) => {
    const existingChart = Chart.getChart(canvasId);
    if (existingChart) {
      existingChart.destroy();
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (leaveReport.length === 0) {
    return <p>No leave report data available.</p>;
  }

  return (
    <div className="container piechart-container">
      {leaveReport.map((item, index) => (
        <div key={index} className="row">
          {UserHeaders.COUNTRY.value === "IN_BGL" || UserHeaders.COUNTRY.value === "IN_CBE" ? (
            <>
              <div className="col-md-4">
                <div className="chart-container text-center">
                  <div className="chart-label">Casual Leaves</div>
                  <canvas id={`casualLeaveChart${index}`} className="w-100"></canvas>
                </div>
              </div>
              <div className="col-md-4">
                <div className="chart-container text-center">
                  <div className="chart-label">Sick Leaves</div>
                  <canvas id={`sickLeaveChart${index}`} className="w-100"></canvas>
                </div>
              </div>
              <div className="col-md-4">
                <div className="chart-container text-center">
                  <div className="chart-label">Annual Leaves</div>
                  <canvas id={`annualLeaveChart${index}`} className="w-100"></canvas>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="chart-container text-center">
                  <div className="chart-label">Earned Leaves</div>
                  <canvas id={`earnedLeaveChart${index}`} className="w-100"></canvas>
                </div>
              </div>
              <div className="col-md-6">
                <div className="chart-container text-center">
                  <div className="chart-label">Sick Leaves</div>
                  <canvas id={`sickLeaveChart${index}`} className="w-100"></canvas>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default LeaveReportChart;

