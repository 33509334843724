import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Replay } from "@sentry/replay";
import { SENTRY_DSN_URL, SENTRY_ENVIRONMENT_URL} from './constant/sentryConstants';

Sentry.init({
  dsn: SENTRY_DSN_URL,
  integrations: [
    new Integrations.BrowserTracing(),
    new Replay(),
  ],
  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: SENTRY_ENVIRONMENT_URL,
});
