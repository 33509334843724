import React, {useState, useEffect} from 'react';
import { UserHeaders } from './constant/localStorageConstants';
import { API_BASE_URL } from './constant/apiConstants';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import logger from './logs/logger';
import {
  handleExpiredAccessToken
} from './authUtils';
import { getAuthHeaders } from './authUtils';
import { useNavigate } from "react-router-dom";
import './styles/DownloadSalarySlip.css';
import { HttpStatusCode } from "axios";



const DownloadSalarySlip = ()=>{
  const userid= UserHeaders.USERID.value;
  const [salarySlips, setSalarySlips]=useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSalarySlips = async() =>{
    try{
     await handleExpiredAccessToken(navigate, setAccessToken);
     const response = await fetch(`${API_BASE_URL}/salaryslipdetails/${userid}`, {
     method: "POST",
     headers: {
       ...getAuthHeaders(accessToken),
      },
      });
      if (response.status === HttpStatusCode.NoContent) {
        logger.warn("SalarySlip Not Found: for Username " + UserHeaders.USERNAME.value );
        return;
      }

      if(response.ok){
        const responseData= await response.json();
        const data = responseData.message;
        setSalarySlips(data);
        logger.info("successfully Retrieved");
      }else{
         /* logger.error('Failed to fetch salaryslip'); */}
    }catch(error){
      logger.error('Error fetching salaryslip : ', error);
    }
         };
         fetchSalarySlips();
   }, [userid]);


  const getYear = (monthAndYear) => monthAndYear.split('/')[0];
  const getMonth = (monthAndYear) => getMonthName(monthAndYear.split('/')[1]);

  const getMonthName = (monthNumber) => {
      const monthNames=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const normalizedMonthNumber= Math.max(1, Math.min(12, parseInt(monthNumber, 10)));
      logger.info("month number:"+normalizedMonthNumber);
      return monthNames[normalizedMonthNumber -1];
  };


  const handleDownload = async (salaryslipid, MonthAndYear) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/downloadsalaryslip/${salaryslipid}`, {
        method: 'POST',
        headers: {
        ...getAuthHeaders(accessToken),
          // Add any necessary headers here
        },
      });

      if (response.ok) {
            const data = await response.json();
            const downloadUrl = data.message; // Assuming 'message' contains the direct download URL

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${MonthAndYear}salaryslip.pdf`); // Set desired file name and extension

            // Append the anchor element to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up: remove the anchor element from the DOM
            document.body.removeChild(link);

        logger.info("Successfully Downloaded");
        setResponseMessage('Salary slip downloaded successfully');
      } else {
      const errorData = await response.json();
      setResponseMessage(errorData.description);
        logger.error('Failed on downloading salaryslip');
      }
    } catch (error) {
      logger.error('Error Downloading salaryslip : ', error);
    }
  };

    const handleView = async (salaryslipid) => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const response = await fetch(`${API_BASE_URL}/viewsalaryslip/${salaryslipid}`, {
          method: 'POST',
          headers: {
          ...getAuthHeaders(accessToken),
            // Add any necessary headers here
          },
        });

        if (response.ok) {
             const data = await response.json();
             window.open(data.message, '_blank');
             setResponseMessage('You can view SalarySlip now');
             logger.info('File view successfully');
        } else {
              const errorData = await response.json();
              setResponseMessage(errorData.description);
              logger.error('Failed to view salary slip');
           }
      } catch (error) {
         logger.error('Error viewing salaryslip: ', error);
      }
    };

  useEffect(() => {
      if (responseMessage) {
        const timer = setTimeout(() => {
        setResponseMessage("");
      }, 5000);
        return () => clearTimeout(timer);
      }
  }, [responseMessage]);

 return(
   <div className="download-salaryslip-container">
     <table className="salaryslip-table">
       <thead>
         <tr>
           <th>Sno</th>
           <th>Month</th>
           <th>Year</th>
           <th>SalarySlip</th>
           <th>Download</th>
         </tr>
       </thead>
       <tbody>
         {salarySlips.map((salaryslip, index) => (
           <tr key={index}>
             <td>{index + 1}</td>
             <td>{getMonth(salaryslip.month_and_year)}</td>
             <td>{getYear(salaryslip.month_and_year)}</td>
             <td>
               <button
                 className="btn btn-outline-primary"
                 style={{ width: "150px" }}
                 onClick={() => handleView(salaryslip.id)} >
                 View
               </button>
             </td>
             <td>
               <button
                 className="btn btn-outline-danger"
                 style={{ width: "150px" }}
                 onClick={() =>
                   handleDownload(salaryslip.id, salaryslip.month_and_year)
                 }
               >
                 Download
               </button>
             </td>
           </tr>
         ))}
       </tbody>
     </table>
     {responseMessage && <div style={{ color: responseMessage.includes("already exists") ? "red" : "green", marginTop: "10px" }}>{responseMessage}</div>}
   </div>

 );
 }
export default DownloadSalarySlip;