import React, { useState, useEffect  } from 'react';
import { useNavigate} from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import logger from './logs/logger';
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/passwordStyles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SetPasswordPage = () => {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [savePasswordMessage, setSavePasswordMessage] = useState('');
  const [token, setToken] = useState('');
  const [passError, setPassError] = useState('');
  const [confirmPassError, setConfirmPassError] = useState('');
  const [userName, setUserName] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const email = getEmailFromState();
    const extractUserName = (email) => {
      const atIndex = email.indexOf('@');
      let name = atIndex !== -1 ? email.substring(0, atIndex) : email;
      if (name.includes('.')) {
        name = name.substring(0, name.indexOf('.'));
      }
      return name;
    };
    const name = extractUserName(email);
    setUserName(name);
  }, []);

  useEffect(() => {
    // Handle the API call when the 'token' state changes
    if (token !== '') {
      handleSetPassword();
    }
  }, [token]);

  const navigate = useNavigate();

  // Password Validation
  const validatePassword = () => {
    /* Password rules: At least one lowercase letter, one uppercase letter, one digit,
       one special character (@, $, !, %, *, ?, &), and 8-20 characters long */
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?~`|\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'<>,.?~`|\\/-]{8,20}$/;

    if (!newPassword.trim()) {
      setPassError("Password is required");
      return false;
    } else if (!passwordRegex.test(newPassword)) {
      setPassError("Password must be 8-20 characters and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.");
      return false;
    } else {
      setPassError("");
      return true;
    }
  };

  // Confirm Password Validation
  const validateConfirmPassword = () => {
    if (!confirmPassword.trim()) {
      setConfirmPassError("Confirm Password is required");
      return false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPassError("Passwords do not match.");
      return false;
    } else {
      setConfirmPassError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate both passwords
    const isPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();

    // Prevent submission if either validation fails
    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }

    const tokenFromUrl = getUrlParameter('token');
    setToken(tokenFromUrl);
  };

  const handleSetPassword = async () => {
    const email = getEmailFromState();

    if (email === '') {
      alert('Email is missing. Unable to set the password.');
      return;
    }

    const requestBody = {
      email: email,
      token: token,
      newPassword: newPassword,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/set-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.code === 200) {
        setSavePasswordMessage(data);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setSavePasswordMessage({ description: data.description || 'Failed to set password' });
      }
    } catch (error) {
      logger.error('Error:', error);
    }
  };

  const getEmailFromState = () => {
    return getUrlParameter('email') || '';
  };

  const getUrlParameter = (name) => {
    // Escape square brackets in the parameter name
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    logger.info('NAME',name)
    // Construct a regular expression to match the parameter in the URL query string
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    logger.info('REGEX',regex)
    // Execute the regular expression on the URL query string
    const results = regex.exec(window.location.search);
    logger.info('RESULTS',results)
    // Return the decoded parameter value if found, otherwise an empty string
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  return (
    <div className="full-width-container">
      <div className="at-gems">AT-Gems</div>
      <div className="password-container ">
        <div className="setpassword-form-container">
          <h1 className="mb-4">Set Your Password</h1>
          {userName && (<p className="greeting"><span role="img" aria-label="hi">👋</span>{" "} Hi, {userName}!</p>)}
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start position-relative">
              <label htmlFor="newPassword" className="password-label"> New Password:</label>
              <input type={showNewPassword ? "text" : "password"} className="form-control password-input" id="newPassword" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Password"/>
              {newPassword && (
                <span className="password-toggle-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                  <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} />
                </span>
              )}
              <div className="text-danger">{passError}</div>
            </div>
            <div className="mb-3 text-start position-relative">
              <label htmlFor="confirmPassword" className="password-label"> Confirm Password:</label>
              <input type={showConfirmPassword ? "text" : "password"} className="form-control password-input" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password"/>
              {confirmPassword && (
                <span className="password-toggle-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                </span>
              )}
              <div className="text-danger">{confirmPassError}</div>
            </div>
            <button type="submit" className="mt-3 submit-button">Set Password</button>
          </form>
          {savePasswordMessage && (
            <div className={`message ${savePasswordMessage.code === 200 ? 'success' : 'error'}`} style={{ color: savePasswordMessage.code === 200 ? 'green' : 'red' }}>
              <p>{savePasswordMessage.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetPasswordPage;
