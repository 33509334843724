import React, { useState, useEffect } from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_BASE_URL } from './constant/apiConstants';
import logger from './logs/logger';
import { UserHeaders } from './constant/localStorageConstants';
import {
  handleExpiredAccessToken
} from './authUtils';
import { getAuthHeaders } from './authUtils';
import { useNavigate } from "react-router-dom";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './styles/UploadSalarySlip.css';

const UploadSalarySlip = () => {
  const [date, setDate] = useState(null);
  const [selects, setSelects] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [file, setFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

  const handleDate = (dt) => {
    setDate(dt);
    let yyyyMM = dt === null ? "" : yyyyMMFormatDate(dt);
    setDateVal(yyyyMM);
  };

  const yyyyMMFormatDate = (d) => {
    if (d) {
      return d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2);
    }
    return d;
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) {
      logger.info("No file selected");
      return;
    }
    if (uploadedFile.type !== "application/pdf") {
      logger.info("File type is not PDF");
      setFileErrorMessage("Please select a PDF file.");
      setFile(null);
      return;
    }
    setFileErrorMessage("");
    setFile(uploadedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

 const createdBy = localStorage.getItem('X-userId'); // Retrieve userId from local storage

    if (!file || !selects || !dateVal || !createdBy) {
      setFileErrorMessage("Please fill in all fields and select a valid file.");
      return;
    }
    const formData = new FormData();
    formData.append('salaryslip', file);
    formData.append('userId', selects);
    formData.append('monthandyear', dateVal);
    formData.append('createdBy', createdBy);

    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/salaryslip/` + selects, {
        ...getAuthHeaders(accessToken),
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setResponseMessage('Salary slip uploaded successfully');
        logger.info('File uploaded successfully');
        setTimeout(() => {
           navigate("/home");
        }, 5000);
      } else {
        const errorData = await response.json();
        setResponseMessage(errorData.description);
        logger.error('Failed to upload salary slip');
      }
    } catch (error) {
      logger.error('Error during fetch operation: ', error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);

        const userProfileResponse = await fetch(`${API_BASE_URL}/alldetails`, {
          method: 'POST',
          headers: {
            ...getAuthHeaders(accessToken),
            [UserHeaders.DEPARTMENTID.headerKey]: UserHeaders.DEPARTMENTID.value,
          },
        });

        if (!userProfileResponse.ok) {
          return;
        }

        const userProfileData = await userProfileResponse.json();

        if (userProfileData.hasOwnProperty('message') && Array.isArray(userProfileData.message)) {
          setUsers(userProfileData.message);
        } else {
          logger.error("User profile data is not a valid array:", userProfileData);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [accessToken]);

  useEffect(() => {
      if (responseMessage) {
        const timer = setTimeout(() => {
          setResponseMessage("");
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [responseMessage]);

  return (
    <div className="application-container">
      <div className="application-form">
        <form onSubmit={handleSubmit}  className="form-field-container">
            <label className="uss-label">Employee:</label>
            <select
              value={selects}
              onChange={(e) => setSelects(e.target.value)}
              className={`application-form select ${selects ? "" : "input-placeholder"}`}
            >
              <option value="" disabled>Employees</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>

            <label style={{marginBottom:"-20px",marginTop:"20px"}}>Month and Year:</label>
            <Datepicker
              selected={date}
              dateFormat="yyyy/MM"
              onChange={(date) => handleDate(date)}
              showMonthYearPicker
              className={`uss-datepicker ${date ? "" : "input-placeholder"}`}
              placeholderText="Select Month and Year"
              maxDate={new Date()}
            />

            <label>Upload Salary Slip:</label>
            <input
              type="file"
              onChange={handleFileUpload}
              className={`form-control uss-upload-file ${file ? "" : "input-placeholder"}`}
            />

          {fileErrorMessage && <p style={{ color: "red" }} onBlur={() => setFileErrorMessage('')}>{fileErrorMessage}</p>}

          <button type="submit" className="btn-uss">
            Upload
          </button>
        </form>
        {responseMessage && <div style={{ color: responseMessage.includes("already exists") ? "red" : "green", marginTop: "10px" }}>{responseMessage}</div>}
      </div>
    </div>
  );
}

export default UploadSalarySlip;
