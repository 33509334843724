import React from 'react';
const logger = {
  info: (...message) => {
    console.log(`%c[INFO]%c ${message.map(stringifyIfObject).join(' ')}`, 'color: green; font-weight: bold;');
  },
  error: (...message) => {
    console.error(`%c[ERROR]%c ${message.map(stringifyIfObject).join(' ')}`, 'color: red; font-weight: bold;');
  },
  warn: (...message) => {
    console.warn(`%c[WARN]%c ${message.map(stringifyIfObject).join(' ')}`, 'color: orange; font-weight: bold;');
  },
};

//  function to convert JSON objects to stringify
const stringifyIfObject = (value) => {
  return typeof value === 'object' ? JSON.stringify(value) : value;
};

export default logger;

