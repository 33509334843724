import React, { useState, useEffect } from "react";
import "./styles/UserPage.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

/**
 *UserPage
 * This page is designed to display user profiles for all users.
 * It contains all user details,Visible for user with role as Admin.
 */
const UserPage = () => {
  const calculateItemsPerPage = () => {
    const availableHeight = window.innerHeight;
    const maxItemsPerPage = Math.floor((availableHeight ) / 75);

    return maxItemsPerPage;
  };

  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage());
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const userProfileResponse = await fetch(`${API_BASE_URL}/alldetails`, {
          method: "POST",
          headers: {
            ...getAuthHeaders(accessToken),
            [UserHeaders.USERROLE.headerKey]: UserHeaders.USERROLE.value,
            [UserHeaders.DEPARTMENTID.headerKey]: UserHeaders.DEPARTMENTID.value,
            [UserHeaders.USERID.headerKey]: UserHeaders.USERID.value,
          },
        });

        if (!userProfileResponse.ok) {
          return;
        }

        const userProfileData = await userProfileResponse.json();

        if (userProfileData.hasOwnProperty('message') && Array.isArray(userProfileData.message)) {
              const sortedMessageData = userProfileData.message.sort((a, b) =>
              a.firstName.localeCompare(b.firstName) );
              setUserDetails(sortedMessageData);
        } else {
              logger.error("User profile data is not a valid format:", userProfileData);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();

    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [accessToken, navigate]);

  // Function to handle editing user profile
  const handleEditUser = (user) => {
    navigate("/editprofile", { state: { userId: user.id } });
  };
  // Function to handle profile page
  const handleProfilePage = (userId) => {
    navigate(`/profile/${userId}`);
  };
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(userDetails.length / itemsPerPage);
  const currentItems = userDetails.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="userpage-container-active-emp">
     <div className="right-content-active-emp">
       <div>
        <table className="allusers-table-active-emp">
          <thead>
            <tr>
              <th className="right-align-emp">Profile</th>
              <th></th>
              <th>Employee ID</th>
              <th>Phone</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user, index) => (
              <tr key={index}>
                <td className="right-align-emp">
                  <img
                    src={
                      user.personalDetails?.profile_photo
                        ? user.personalDetails?.profile_photo
                        : "./profileshadow.jpg"
                    }
                    className="user-profile-photo-active-emp" onClick={() => handleProfilePage(user.id)} />
                </td>
                <td onClick={() => handleProfilePage(user.id)} className="left-align-emp">
                  <span>{user.firstName} {user.lastName}</span><br />
                  <span className="email">{user.email}</span></td>
                <td onClick={() => handleProfilePage(user.id)}>{user.employeeId}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.jobPosition ? user.jobPosition.name : "Job Position Not Defined"}</td>
                <td>{user.department ? user.department.name : "Department Not Defined"}</td>
                <td>
                  <button className="edit-button-active-emp" onClick={() => handleEditUser(user)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
       <div>
        <ReactPaginate
          previousLabel={ <>
           <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
           <span className="icon-text">Previous</span>
          </>}
          nextLabel={ <>
           <div className="next-container">
            <span className="icon-text">Next</span>
            <FontAwesomeIcon icon={faArrowRight} className="icon-right"  />
           </div>
          </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-active-emp"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button-active-emp" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button-active-emp" : ""}
        />
       </div>
     </div>
    </div>
  );
};

export default UserPage;
