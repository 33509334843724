import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from './constant/apiConstants';
import logger from './logs/logger';
import { UserHeaders } from "./constant/localStorageConstants";
import { useNavigate } from 'react-router-dom';
import {
  handleExpiredAccessToken
} from './authUtils';
import './styles/ApprovalPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAuthHeaders } from './authUtils';
import ReactPaginate from "react-paginate";

const ApprovalPage = () => {
    const [salarySlips, setSalarySlips] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [modalData, setModalData] = useState({ status: '', username: '', comment: '', salarySlipId: null });
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({});
    const [itemsPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(0);
    const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

    useEffect(() => {
        const fetchSalarySlips = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/allsalaryslips`, {
                    headers: {
                        ...getAuthHeaders(accessToken),
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();
                const data = responseData.message;

                setSalarySlips(data);

                const userIds = [...new Set(data.flatMap(slip => [slip.created_by, slip.userid]))];
                const userDetails = await fetchAllUserDetails(userIds);
                setUserDetails(userDetails);
            } catch (error) {
                logger.error(`Error fetching salary slips: ${error.message}`);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        fetchSalarySlips();
    }, [navigate, accessToken]);

    const fetchAllUserDetails = async (userIds) => {
        try {
            const userDetails = await Promise.all(userIds.map(async (userid) => {
                const response = await fetch(`${API_BASE_URL}/user/${userid}`, {
                    headers: {
                        ...getAuthHeaders(accessToken),
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

              const userResponseData = await response.json();
              const userData = userResponseData.message;
              return userData;
            }));

            return userDetails.reduce((acc, user) => {
                acc[user.id] = user;
                return acc;
            }, {});
        } catch (error) {
            logger.error(`Error fetching user details: ${error.message}`);
            return {};
        }
    };

    const handleView = async (salaryslipid) => {
        try {
            await handleExpiredAccessToken(navigate, setAccessToken);
            const response = await fetch(`${API_BASE_URL}/viewsalaryslip/${salaryslipid}`, {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(accessToken),
                },
            });

            if (response.ok) {
                const data = await response.json();
                window.open(data.message, '_blank');
                logger.info("Successfully Displayed");
            } else {
                logger.error('Failed to view salary slip');
            }
        } catch (error) {
            logger.error('Error viewing salary slip: ', error);
        }
    };

    const getYear = (monthAndYear) => monthAndYear.split('/')[0];
    const getMonth = (monthAndYear) => getMonthName(monthAndYear.split('/')[1]);

    const getMonthName = (monthNumber) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const normalizedMonthNumber = Math.max(1, Math.min(12, parseInt(monthNumber, 10)));
        return monthNames[normalizedMonthNumber - 1];
    };

    const hasApprovalRights = true;

    const setStatus = async (status, comment) => {
        try {
            const salarySlipId = modalData.salarySlipId;
            const userId = UserHeaders.USERID.value;
            const updatedData = { comments: comment, status: status, approved_by: userId };

            const response = await fetch(`${API_BASE_URL}/edit-salary-slip/${salarySlipId}`, {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(accessToken),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            logger.info('Salary Slip Edited Successfully');
            const updatedSalarySlips = salarySlips.map(salarySlip => {
                if (salarySlip.id === salarySlipId) {
                    return {
                        ...salarySlip,
                        status: { name: status },
                        comments: comment,
                    };
                }
                return salarySlip;
            });
            setSalarySlips(updatedSalarySlips);
        } catch (error) {
            logger.error(`Error editing salary slip: ${error.message}`);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
        setShowPopup(false);
    };

    const handleButtonClick = (status, user, salarySlipId) => {
        setModalData({ status, username: user, comment: '', salarySlipId });
        setShowPopup(true);
    };

    const handleModalClose = () => setShowPopup(false);

    const handleModalSubmit = () => {
        setStatus(modalData.status, modalData.comment);
    };

    const offset = currentPage * itemsPerPage;
      const pageCount = Math.ceil(salarySlips.length / itemsPerPage);
      const currentItems = salarySlips.slice(offset, offset + itemsPerPage);
      const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
      };


    return (
        <div className="approval-salaryslip-container">
            <table className="approval-salaryslip-table">
                <thead>
                    <tr>
                        <th>Sno</th>
                        <th>Uploaded By</th>
                        <th>Employee Name</th>
                        <th>Salary Slip</th>
                        <th>Month</th>
                        <th>Year</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((salaryslip, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{userDetails[salaryslip.created_by]?.username || 'Loading...'}</td>
                            <td>{userDetails[salaryslip.userid]?.username || 'Loading...'}</td>
                            <td>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{ width: "150px" }}
                                    onClick={() => handleView(salaryslip.id)}
                                >
                                    View
                                </button>
                            </td>
                            <td>{getMonth(salaryslip.month_and_year)}</td>
                            <td>{getYear(salaryslip.month_and_year)}</td>
                            <td>
                                {salaryslip.status && salaryslip.status.name !== null ? (
                                    <div>{salaryslip.status.name}</div>
                                ) : (
                                    <div>
                                        {hasApprovalRights && salaryslip.approvalId == null &&
                                            salaryslip.status?.name !== "Approved" &&
                                            salaryslip.status?.name !== "Rejected" && (
                                                <div>
                                                    <button
                                                        className="approve-button"
                                                        onClick={() => handleButtonClick("APPROVED", userDetails[salaryslip.userid]?.username, salaryslip.id)}
                                                    >
                                                        Approve
                                                    </button>
                                                    <button
                                                        className="reject-button"
                                                        onClick={() => handleButtonClick("REJECTED", userDetails[salaryslip.userid]?.username, salaryslip.id)}
                                                    >
                                                        Reject
                                                    </button>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={showPopup} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalData.status} Salary Slip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formComment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={modalData.comment}
                                onChange={(e) => setModalData({ ...modalData, comment: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleModalSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="reports-pagination">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination-reports"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                previousClassName={currentPage === 0 ? "disabled-button" : ""}
                nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
              />
            </div>
        </div>
    );
};

export default ApprovalPage;
