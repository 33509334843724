import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles/CustomCalendar.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { useNavigate } from "react-router-dom";
import {
  handleExpiredAccessToken,
  getAuthHeaders,
} from "./authUtils";
import { UserHeaders } from './constant/localStorageConstants';
import logger from './logs/logger';
import HolidayProgress from './HolidayProgress';

const CustomCalendar = ({ onDateReasonsAvailable }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [leaveDates, setLeaveDates] = useState([]);
  const navigate = useNavigate();
  const userId = UserHeaders.USERID.value;
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

  useEffect(() => {
    const fetchLeaveDatesFromAPI = async () => {
      const userRole = parseInt(UserHeaders.USERROLE.value, 10);
      const userCountry =  UserHeaders.COUNTRY.value;
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const response = await fetch(`${API_BASE_URL}/holidays`, {
           headers: {
             ...getAuthHeaders(accessToken),
             'X-Country': userCountry,
             'X-UserRole': userRole,
              'X-UserId' : userId
           }
        });
        if (response.ok) {
          const data = await response.json();
          if (data.code === 200 && Array.isArray(data.message)) {
            setLeaveDates(data.message);
            updateDateReasons(selectedDate);
          } else {
            logger.error("Unexpected response structure:", data);
            setLeaveDates([]);
          }
        } else {
          logger.error("Failed to fetch holidays");
        }
      } catch (error) {
        logger.error("Error fetching leave dates:", error);
      }
    };

    fetchLeaveDatesFromAPI();
  }, [accessToken, navigate, userId]);

  const updateDateReasons = (date) => {
    const reasons = getReasonsForSelectedDate(date);
    onDateReasonsAvailable(reasons.length > 0);
  };

  useEffect(() => {
    updateDateReasons(selectedDate);
  }, [selectedDate, leaveDates, onDateReasonsAvailable]);

const getReasonsForSelectedDate = (date) => {
  const selectedDateString = date.toDateString();

  const reasons = leaveDates
    .filter(leaveDate => {
      const startDate = new Date(leaveDate.startDate);
      const endDate = new Date(leaveDate.endDate);
      const selectedDate = new Date(selectedDateString);
      const startDateWithoutTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      return selectedDate >= startDateWithoutTime && selectedDate <= endDate;
    })
    .map(leaveDate => leaveDate.reason);

  return reasons;
};

const tileContent = ({ date, view }) => {
  const leaveDatesForDay = leaveDates.filter((leaveDate) => {
    const leaveStartDate = new Date(leaveDate.startDate);
    const leaveEndDate = new Date(leaveDate.endDate);
    const leaveStartDateWithoutTime = new Date(
      leaveStartDate.getFullYear(),
      leaveStartDate.getMonth(),
      leaveStartDate.getDate()
    );
    return date >= leaveStartDateWithoutTime && date <= leaveEndDate;
  });
    return leaveDatesForDay.length > 0 ? (
      <div className="leave-date-tile">
        {leaveDatesForDay.map((leaveDate, index) => (
          <span key={index} className="leave-reason">
            <span>*</span>
          </span>
        ))}
      </div>
    ) : null;
  };

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  const renderSelectedDateReasons = () => {
    const reasons = getReasonsForSelectedDate(selectedDate);

    if (reasons && reasons.length > 0) {
      return (
        <div className="selected-date-reasons">
          <ul>
            {reasons.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };
  const handleClickDay = (value) => {
    setSelectedDate(value);
  };

  return (
    <div className="new-custom-container">
      <div className="custom-container">
        <h1> Calendar </h1>
        <div>
          <Calendar
            className="custom"
            calendarType="gregory"
            onChange={handleDateChange}
            onClickDay={handleClickDay}
            value={selectedDate}
            tileContent={tileContent}
          />
        </div>
      </div>
      {renderSelectedDateReasons() && (
        <div className="combined-container">
          <div className="day-date">
            <p>{selectedDate.toLocaleDateString('en-US', { weekday: 'short' })}&nbsp;&nbsp;</p>
            <p>{selectedDate.getDate()}</p>
          </div>
          <div className="reason-container">
            {renderSelectedDateReasons()}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;
