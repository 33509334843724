import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import "./styles/AddHoliday.css";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';

const AddHolidayForm = ({ addHoliday }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newHoliday, setNewHoliday] = useState({
    startDate: "",
    endDate: "",
    reason: "",
    countries: []
  });
  const [errors, setErrors] = useState({});
  const [registerResponse, setRegisterResponse] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [minEndDate, setMinEndDate] = useState("");

  useEffect(() => {
    if (location.state && location.state.holidayToEdit) {
      setNewHoliday(location.state.holidayToEdit);
      setEditMode(true);
    }
  }, [location.state]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const response = await fetch(`${API_BASE_URL}/regionaccess_countries`, {
          method: 'POST',
          headers: {
            [UserHeaders.USERID.headerKey]: UserHeaders.USERID.value,
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
          },
        });

        if (response.ok) {
          const data = await response.json();
          const countryNames = data.message.map((country) => ({
            name: country.name,
            enumValue: country.enumValue,
            id: country.id
          }));
          countryNames.sort((a, b) => a.name.localeCompare(b.name));
          setCountries(countryNames);
        } else {
          logger.error("Failed to fetch countries");
        }
      } catch (error) {
        logger.error("Error fetching countries:", error);
      }
    }
    fetchCountries();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewHoliday({ ...newHoliday, [name]: value });
    if (name === "startDate") {
      setMinEndDate(value);
      if (newHoliday.endDate && newHoliday.endDate < value) {
        setNewHoliday({ ...newHoliday, endDate: "", startDate: value });
      }
    }
  };

  const handleCountryChange = (country) => {
    const isSelected = newHoliday.countries && newHoliday.countries.includes(country);
    let updatedCountries = [...newHoliday.countries];
    if (isSelected) {
      updatedCountries = updatedCountries.filter(c => c !== country);
    } else {
      updatedCountries.push(country);
    }
    setNewHoliday(prevState => ({
      ...prevState,
      countries: updatedCountries
    }));
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!newHoliday.startDate) {
      newErrors.startDate = "Start Date is required";
      isValid = false;
    }

    if (!newHoliday.endDate) {
      newErrors.endDate = "End Date is required";
      isValid = false;
    }

    if (!newHoliday.reason) {
      newErrors.reason = "Reason is required";
      isValid = false;
    }

    if (newHoliday.startDate > newHoliday.endDate) {
      newErrors.endDate = "End Date must be the same or after Start Date";
      isValid = false;
    }

    if (!newHoliday.countries || newHoliday.countries.length === 0) {
      newErrors.countries = "Select at least one country";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);

        const response = await fetch(`${API_BASE_URL}/createholidays`, {
          method: "POST",
          headers: {
            [UserHeaders.USERROLE.headerKey]: UserHeaders.USERROLE.value,
            "Content-Type": "application/json",
            ...getAuthHeaders(accessToken),
          },
          body: JSON.stringify(newHoliday),
        });
        if (response.ok) {
          const data = await response.json();
          setNewHoliday({ startDate: "", endDate: "", reason: "", countries: [] });
          setRegisterResponse(data);
          setTimeout(() => {
            navigate("/holidaydetails");
          }, 3000);
        } else {
          logger.error("Failed to add holiday");
        }
      } catch (error) {
        logger.error("Error adding holiday:", error);
      }
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="holiday-application-container container">
      <div className="form-container row justify-content-center">
        <div className="col-12">
          <form className="holiday-application-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="startDate">Start date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={newHoliday.startDate}
                onChange={handleInputChange}
                className={newHoliday.startDate ? "" : "input-placeholder"}
                min={getCurrentDate()}
              />
              {errors.startDate && <p className="error-message">{errors.startDate}</p>}
            </div>

            <div className="mb-3">
              <label htmlFor="endDate" className="form-label">End date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={newHoliday.endDate}
                onChange={handleInputChange}
                className={newHoliday.endDate ? "" : "input-placeholder"}
                min={`${minEndDate || getCurrentDate()}`}
              />
              {errors.endDate && <p className="error-message">{errors.endDate}</p>}
            </div>

            <div className="mb-3">
              <label htmlFor="reason" className="form-label">Reason</label>
              <input
                type="text"
                id="reason"
                name="reason"
                value={newHoliday.reason}
                onChange={handleInputChange}
                className={newHoliday.reason ? "" : "input-placeholder"}
              />
              {errors.reason && <p className="error-message">{errors.reason}</p>}
            </div>

            <div className="mb-3 country-select">
              <div className="checkbox-list">
                {countries.map((country) => (
                  <div key={country.id} className="checkbox-item">
                    <input
                      type="checkbox"
                      id={`checkbox-${country.id}`}
                      value={country.enumValue}
                      checked={newHoliday.countries.includes(country.enumValue)}
                      onChange={() => handleCountryChange(country.enumValue)}
                    />
                    <label htmlFor={`checkbox-${country.id}`}>{country.name}</label>
                  </div>
                ))}
              </div>
              {errors.countries && <p className="error-message">{errors.countries}</p>}
            </div>

            <div className="mt-4">
              <button type="submit" className="btn btn-primary">{editMode ? "Update" : "Submit"}</button>
              <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
            </div>

            {registerResponse && (
              <div className="response-message"><p>{registerResponse.description}</p></div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddHolidayForm;
