import React, { useState, useEffect } from "react";
import "./styles/EmployeeApprovalPage.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const EmployeeStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};


const EmployeeApproval = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const userProfileResponse = await fetch(`${API_BASE_URL}/employeeapproval`, {
          method: "POST",
          headers: {
            ...getAuthHeaders(accessToken),
            [UserHeaders.USERROLE.headerKey]: UserHeaders.USERROLE.value,
            [UserHeaders.USERID.headerKey]: UserHeaders.USERID.value,
          },
        });

        if (!userProfileResponse.ok) {
          logger.warn("User profile response not OK:", userProfileResponse);
          return;
        }

        const userProfileData = await userProfileResponse.json();

        if (userProfileData.hasOwnProperty('message') && Array.isArray(userProfileData.message)) {
          const sortedMessageData = userProfileData.message.sort((a, b) =>
            a.firstName.localeCompare(b.firstName));
          setUserDetails(sortedMessageData);
        } else if (Array.isArray(userProfileData)) {
          const sortedUserDetails = userProfileData.sort((a, b) => a.firstName.localeCompare(b.firstName));
          setUserDetails(sortedUserDetails);
        } else {
          logger.error("User profile data is not a valid format:", userProfileData);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [accessToken]);

  const handleButtonClick = (actionType, user) => {
    setComments("");
    setStatus(actionType.toUpperCase());
    setSelectedUser(user);
    setShowPopup(true);
  };

  const handleApproveRejectSubmit = async (event) => {
    event.preventDefault();
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/update-employeestatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(accessToken),
        },
        body: JSON.stringify({
          id: selectedUser.id,
          employeeStatus: status,
          comments: comments,
          approvedBy: UserHeaders.USERID.value,
        }),
      });

      if (response.ok) {
        setShowPopup(false);
        setSelectedUser({});
        setComments("");
        setStatus("");
        navigate('/allusers');
       } else {
        logger.error("Error updating employee approval status:", await response.json());
      }
    } catch (error) {
      logger.error("An error occurred during the API call:", error.message);
    }
  };

  const handleProfilePage = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(userDetails.length / itemsPerPage);
  const currentItems = userDetails.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="userpage-container-emp">
     <div className="right-content-emp">
      <div>
        <table className="allusers-table-emp">
          <thead>
            <tr>
              <th className="right-align-emp">Profile</th>
              <th></th>
              <th>Employee ID</th>
              <th>Phone</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Employee Approval</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user, index) => (
              <tr key={index}>
                <td className="right-align-emp">
                  <img
                    src={
                      user.personalDetails?.profile_photo
                        ? user.personalDetails?.profile_photo
                        : "./profileshadow.jpg"
                    }
                    className="user-profile-photo-emp" onClick={() => handleProfilePage(user.id)} />
                </td>
                <td onClick={() => handleProfilePage(user.id)} className="left-align-emp">
                    <span>{user.firstName} {user.lastName}</span><br />
                    <span className="email">{user.email}</span></td>
                <td onClick={() => handleProfilePage(user.id)}>{user.employeeId}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.jobPosition.name}</td>
                <td>{user.department ? user.department.name : "Department Not Defined"}</td>
                <td>
                <div class="button-container-emp">
                  <button className="approve-button-emp" onClick={() => handleButtonClick(EmployeeStatus.APPROVED, user)}>Approve</button>
                  <button className="reject-button-emp" onClick={() => handleButtonClick(EmployeeStatus.REJECTED, user)}>Reject</button>
                </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={ <>
           <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
           <span className="icon-text">Previous</span>
          </>}
          nextLabel={<>
           <div className="next-container">
            <span className="icon-text">Next</span>
            <FontAwesomeIcon icon={faArrowRight} className="icon-right"  />
           </div>
          </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-active-user-emp"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button-emp" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button-emp" : ""}
        />
      </div>
      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        modal
        nested
        contentClassName="popup-content">
        {(close) => (
          <div className="popup-container">
            <h3 className="popup-title">Update Employee Status</h3>
            <label className="popup-label">
              Comments:
              <input className="popup-input input-style" type="text" value={comments} onChange={(e) => setComments(e.target.value)}/>
            </label>
            <div className="popup-buttons">
              <button className="popup-submit" onClick={handleApproveRejectSubmit}>Submit</button>
              <button className="popup-close" onClick={close}>Cancel</button>
            </div>
          </div>
        )}
      </Popup>
    </div>
   </div>
  );
};

export default EmployeeApproval;
