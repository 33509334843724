// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  /*background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);*/
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
.auth-form-container{
padding: 5rem;
border: 1px solid white;
border-radius: 10px;
}
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}
.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,2EAA2E;AAC7E;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;AACA;AACA,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB;AACA;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  min-height: 100vh;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  /*background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);*/\n}\n\n.auth-form-container, .login-form, .register-form {\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (min-width: 600px) {\n.auth-form-container{\npadding: 5rem;\nborder: 1px solid white;\nborder-radius: 10px;\n}\n}\n\nlabel {\n  text-align: left;\n  padding: 0.25rem 0;\n}\n\ninput {\n  margin: 0.5rem 0;\n  padding: 1rem;\n  border: none;\n  border-radius: 10px;\n}\n\nbutton {\n  border: none;\n  background-color: white;\n  padding: 20px;\n  border-radius: 10px;\n  cursor: pointer;\n  color: #7439db;\n}\n.link-btn {\n  background: none;\n  color: white;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
