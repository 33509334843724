import React, { useState, useEffect } from "react";
import "./styles/applicationform.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';

const LeaveApplication = () => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const [casualReasons, setCasualReasons] = useState([]);
  const [sickReasons, setSickReasons] = useState([]);
  const [annualReasons, setAnnualReasons] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [othersReason, setOthersReason] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const navigate = useNavigate();
  const [applyResponse, setApplyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accessToken, setAccessToken] = useState(
    UserHeaders.ACCESSTOKEN.value
  );
 const [validationErrors, setValidationErrors] = useState({
    leaveType: "",
    fromDate: "",
    toDate: "",
    reason: "",
  });

const country = UserHeaders.COUNTRY.value;
const userId = UserHeaders.USERID.value;

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
         const response = await fetch(`${API_BASE_URL}/metadata/leavetypes?country=${country}&userId=${userId}`, {
         method: 'POST',
         headers: {
         ...getAuthHeaders(accessToken),                                                                                    },
         });
        if (response.ok) {
          const data = await response.json();
          const optionsArray = Object.keys(data).map((key) => {
            return {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            };
          });

          setLeaveTypes(optionsArray);
        } else {
          logger.error("Failed to fetch dropdown options");
        }
      } catch (error) {
        logger.error(
          "An error occurred while fetching dropdown options:",
          error
        );
      }
    };

    const fetchReasons = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const response = await fetch(`${API_BASE_URL}/metadata/reasons`, {
          method: 'GET',
          headers: getAuthHeaders(accessToken),
        });
        if (response.ok) {
          const data = await response.json();
          const reasonMap = {
            'CASUAL': [],
            'SICK': [],
            'ANNUAL': [],
          };

          Object.keys(data).forEach((key) => {
            const reason = {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            };

            const leaveTypeName = data[key].leaveType.name.toUpperCase();

             // Check if the leaveType corresponds to one of the defined leave types
             if (reasonMap[leaveTypeName]) {
               reasonMap[leaveTypeName].push(reason);
             }
          });

          setCasualReasons(reasonMap.CASUAL);
          setSickReasons(reasonMap.SICK);
          setAnnualReasons(reasonMap.ANNUAL);
        } else {
          logger.error("Failed to fetch reasons");
        }
      } catch (error) {
        logger.error("An error occurred while fetching reasons:", error);
      }
    };

    fetchDropdownOptions();
    fetchReasons();
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      const daysDifference = Math.ceil((to - from + 1) / (1000 * 60 * 60 * 24));
      setNumberOfDays(daysDifference);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (leaveType === 'CASUAL') {
      setReasons(casualReasons);
    } else if (leaveType === 'SICK') {
      setReasons(sickReasons);
    } else if (leaveType === 'ANNUAL' || leaveType === 'EARNED') {
      setReasons(annualReasons);
    } else {
      setReasons([]);
    }
    setReason("");
  }, [leaveType, casualReasons, sickReasons, annualReasons]);

  const validateForm = () => {
    const errors = {
      leaveType: leaveType ? "" : "Leave Type is required",
      fromDate: fromDate ? "" : "From Date is required",
      toDate: toDate ? "" : "To Date is required",
      reason: reason ? "" : "Reason is required",
    };

    if (reason === "SICK_OTHERS" || reason === "ANNUAL_OTHERS" || reason === "CASUAL_OTHERS") {
       errors.othersReason = othersReason ? "" : "Other Reason is required";
    }

    if ((leaveType === 'CASUAL' || leaveType === 'ANNUAL') && fromDate) {
      const fromDateObj = new Date(fromDate);
      const today = new Date();
      fromDateObj.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (fromDateObj < today) {
        errors.fromDate = `Cannot select a past date for ${leaveType.toLowerCase()} leave`;
      }
    }
  if (fromDate && toDate) {
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    if (fromDateObj > toDateObj) {
      errors.toDate = "From Date should be before the To Date";
    }
  }
    setValidationErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = async (e) => {
    await handleExpiredAccessToken(navigate, setAccessToken);
    if (isSubmitting) return;

    setIsSubmitting(true);

    e.preventDefault();
    if (!validateForm()) {
        setIsSubmitting(false);
        return;
      }

    try {
      //         await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(
        `${API_BASE_URL}/apply-leave/${UserHeaders.USERID.value}`,
        {
          method: "POST",
          headers: {
            ...getAuthHeaders(accessToken),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            leaveType: leaveType,
            fromDate: fromDate,
            toDate: toDate,
            no_of_days: numberOfDays,
            reason: reason,
            otherReason:othersReason,
          }),
        }
      );

      if (response.ok) {
        logger.info("Leave application submitted successfully!");
        const successResponse = await response.json();
        logger.info("apply status:", successResponse.description);
        setApplyResponse({ description: successResponse.description, code: successResponse.code });
        setLeaveType("");
        setFromDate("");
        setToDate("");
        setReason("");
        setNumberOfDays(0);
        setTimeout(() => navigate("/home/leaverequest"), 3000);
      } else {
        const errorResponse = await response.json();
         setApplyResponse({ description: errorResponse.message });

        logger.error(
          "Error submitting leave application:",
          response.status,
          errorResponse
        );
      }
    } catch (error) {

      logger.error("An error occurred during submission:", error.message);
    }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleReasonChange = (e) => {
    const selectedReason = e.target.value;
    setReason(selectedReason);
    if (selectedReason !== "ANNUAL_OTHERS" && selectedReason !== "SICK_OTHERS" && selectedReason !== "CASUAL_OTHERS") {
      setOthersReason("");
    }
  };

return (
  <div className="application-container">
    <div className="form-container">
      <form className="leave-application-form" method="POST">
        <div className="form-row">
          <div className="form-group full-width">
            <label>
              Leave Type:
              <select value={leaveType} onChange={(e) => setLeaveType(e.target.value)} className={leaveType ? "" : "input-placeholder"} >
                <option value="" disabled >
                  Select Leave Type
                </option>
                {leaveTypes && leaveTypes.length > 0 ? (
                  leaveTypes.map((option, index) => (
                    <option key={index} value={option.enumValue}>
                      {option.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No Leave Types Available
                  </option>
                )}
              </select>
            </label>
            <div> <p className="error-message">{validationErrors.leaveType}</p> </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group half-width">
            <label>
              From:
              <input
                type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} className={fromDate ? "" : "input-placeholder"}
                min={(leaveType === 'CASUAL'|| leaveType === 'ANNUAL') ? new Date().toISOString().split('T')[0] : ''} />
            </label>
            <div> {validationErrors.fromDate && ( <p className="error-message">{validationErrors.fromDate}</p> )} </div>
          </div>
          <div className="form-group half-width">
            <label>
              To:
              <input
                type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} className={toDate ? "" : "input-placeholder"}
                min={(leaveType === 'CASUAL'|| leaveType === 'ANNUAL') ? new Date().toISOString().split('T')[0] : ''} />
            </label>
            <div> {validationErrors.toDate && ( <p className="error-message">{validationErrors.toDate}</p> )} </div>
          </div>
        </div>
        &nbsp;
        <div className="form-row">
          <div className="form-group full-width">
            <label>
              Number of Days:
              <input type="number" value={numberOfDays} readOnly className={numberOfDays ? "" : "input-placeholder"}/>
            </label>
          </div>
        </div>
        &nbsp;
        <div className="form-row">
          <div className="form-group full-width">
            <label>
              Reason for Leave:
              <select value={reason} onChange={handleReasonChange} className={reason ? "" : "input-placeholder"}>
                <option value="" disabled>Select Reason</option>
                  {reasons.length > 0 ? (
                    reasons.map((option, index) => (
                      <option key={index} value={option.enumValue}>{option.name}</option>
                    ))
                    ) : (
                      <option value="" disabled>No Reasons Available</option>
                    )}
              </select>
            </label>
            <div> <p className="error-message">{validationErrors.reason}</p> </div>
          </div>
        </div>
        {(reason === "SICK_OTHERS" || reason === "ANNUAL_OTHERS" || reason === "CASUAL_OTHERS") && (
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                Other Reasons:
                <textarea value={othersReason} onChange={(e) => setOthersReason(e.target.value)} className={othersReason ? "" : "input-placeholder"}/>
              </label>
              <div> <p className="error-message">{validationErrors.othersReason}</p> </div>
            </div>
          </div>
        )}
        &nbsp;
        <div className="form-row">
          <button type="button" onClick={handleSubmit} disabled={isSubmitting} className="submit-button">
            Submit
          </button>
          <button type="button" onClick={handleCancel} className="cancel-button">
            Cancel
          </button>
        </div>
        {applyResponse && ( <div className={`apply-response ${ applyResponse.code === 201 ? "success" : "error"}`}> <p>{applyResponse.description}</p> </div>)}
      </form>
    </div>
  </div>
);

};

export default LeaveApplication;