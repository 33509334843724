// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-salaryslip-container {
  text-align: center;
}

.salaryslip-table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
  float: right;
}

.salaryslip-table th, .salaryslip-table td {
  border: 1px solid #ddd;
  padding: 8px
}

.salaryslip-table th {
  background-color: #f2f2f2
}

.approval-salaryslip-container {
  text-align: center;
  width: 254vh;
  height: 100vh;
}

.approval-salaryslip-table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/styles/DownloadSalarySlip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB;AACF;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd","sourcesContent":[".download-salaryslip-container {\n  text-align: center;\n}\n\n.salaryslip-table {\n  margin: 0 auto;\n  border-collapse: collapse;\n  width: 100%;\n  float: right;\n}\n\n.salaryslip-table th, .salaryslip-table td {\n  border: 1px solid #ddd;\n  padding: 8px\n}\n\n.salaryslip-table th {\n  background-color: #f2f2f2\n}\n\n.approval-salaryslip-container {\n  text-align: center;\n  width: 254vh;\n  height: 100vh;\n}\n\n.approval-salaryslip-table {\n  margin: 0 auto;\n  border-collapse: collapse;\n  width: 100%;\n  float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
