import React, { useState, useEffect, useRef  } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Navigation from './Navigation';
import "./styles/ProfilePage.css";
import { UserHeaders } from './constant/localStorageConstants';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Menu } from 'antd';
import { API_BASE_URL } from './constant/apiConstants';
import { faUser, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hamburger from 'hamburger-react';
import logger from './logs/logger';
import { handleExpiredAccessToken, getAuthHeaders } from './authUtils';
import { HttpStatusCode } from "axios";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";

const ProfilePage = () => {
  const [activeNavItem, setActiveNavItem] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImage, setProfileImage] = useState('');
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const dropdownRef = useRef(null);


useEffect(() => {
    const url = location.pathname;
    if (url === '/home') {
        setActiveNavItem(0);
    } else if (url.startsWith('/home/')) {
        setActiveNavItem(null);
    } else {
        const segments = url.split('/');
        const lastSegment = segments[segments.length - 1];
        switch (lastSegment) {
           case 'approvesalaryslip':
                    setActiveNavItem(9);
                    break;
        case 'exemployees':
            setActiveNavItem(8);
            break;
        case 'userreports':
            setActiveNavItem(7);
            break;
        case 'reports':
            setActiveNavItem(7);
            break;
        case 'downloadsalaryslip':
            setActiveNavItem(6);
            break;
        case 'uploadsalaryslip':
            setActiveNavItem(5);
            break;
        case 'addholiday':
            setActiveNavItem(4);
            break;
        case 'addemployee':
            setActiveNavItem(3);
            break;
        case 'allusers':
            setActiveNavItem(2);
            break;
        case 'profile':
            setActiveNavItem(1);
            break;
        default:
            break;
        }
    }
  }, [location.pathname]);

 const handleLogout = () => {
   localStorage.clear();
   navigate("/login");
   setIsDropdownOpen(false);
 };

  const handleNavItemClick = (index) => {
    setActiveNavItem(index);
  };

 const handleYourProfile = () => {
   navigate("/profile");
   setIsDropdownOpen(false);
 };

 const handleToggleNavigation = () => {
   setIsNavigationOpen(!isNavigationOpen);
 };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        const profileImageResponse = await fetch(`${API_BASE_URL}/getprofileimage/${UserHeaders.USERID.value}`, {
           method: 'POST',
           headers: {
             [UserHeaders.USERNAME.headerKey]: UserHeaders.USERNAME.value,
              ...getAuthHeaders(accessToken),
           },
        });

        if (profileImageResponse.status === HttpStatusCode.NoContent) {
           setProfileImage("./profileshadow.jpg");
           logger.warn("Profile Picture Not Found: for Username " + UserHeaders.USERNAME.value );
           return;
        }

        if(!profileImageResponse.ok){
          /* logger.warn("Profile Image response Data not ok: ", profileImageResponse); */
          setProfileImage("./profileshadow.jpg");
          return;
        }

        const responseData = await profileImageResponse.json();
        /* logger.info("get profile response: ", responseData); */
        if(responseData.message == null){
          /* logger.info("Profile Image not found"); */
          setProfileImage("./profileshadow.jpg");
        } else {
          setProfileImage(responseData.message);
        }
      } catch (error) {
        /* logger.error('Error fetching image:', error); */
      }
    };

    fetchProfile();
  }, [UserHeaders.USERID.value]);

 const handleMenuClick = ({ key }) => {
   if (key === "logout") {
     handleLogout();
   } else if (key === "yourProfile") {
     handleYourProfile();
   }
 };

 const toggleNavigation = () => {
   logger.info("Toggling navigation");
   setIsNavigationOpen(!isNavigationOpen);
 };

 const menu = (
   <Menu onClick={handleMenuClick}>
     <Menu.Item key="yourProfile">
       <FontAwesomeIcon icon={faUser} /> Your Profile
     </Menu.Item>
     <Menu.Item key="logout">
       <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
     </Menu.Item>
     <Menu.Divider />
     <div className="menu-username">{UserHeaders.USERNAME.value}</div>
   </Menu>
 );

 return (
   <body>
     <main>
       <div className="container-fluid">
         <div className="row">
           <header className="sticky-header">
             <h1 className="title">AT-Gems</h1>
             <div className="profile-section">
               <Dropdown
                 overlay={menu}
                 trigger={["click"]}
                 visible={isDropdownOpen}
               >
                 <img
                   src={`${profileImage}`}
                   className="profile-photo"
                   onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                   ref={dropdownRef}
                 />
               </Dropdown>
             </div>
           </header>
         </div>

         <div className="row">
           <div className="col">
             <Navigation
               activeNavItem={activeNavItem}
               handleNavItemClick={handleNavItemClick}
               userRole={UserHeaders.USERROLE.value}
               closeNavigation={() => setIsNavigationOpen(false)}
             />

             <div className="main-content">
               <div className="right-content">
                 <Outlet />
               </div>
             </div>
           </div>
         </div>
       </div>
     </main>
   </body>
 );
};

export default ProfilePage;