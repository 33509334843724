import * as React from 'react';
import Leavedetails from './styles/LeaveTable.css';

const LeaveDetails = () => {
 const data=[
 {
sno: "1",
Holiday: "Pongal/sankranti",
Date: "15th January",
Day: "Monday"
 },
  {
 sno: "2",
 Holiday: "Republic Day*",
 Date: "26th January",
 Day: "Friday"
  },
   {
  sno: "3",
  Holiday: "Chandramana Ugadi",
  Date: "9th April",
  Day: "Tuesday"
   },
    {
   sno: "4",
   Holiday: "May Day",
   Date: "1st May",
   Day: "Wednesday"
    },
     {
    sno: "5",
    Holiday: "Independence Day*",
    Date: "15th August",
    Day: "Thursday"
     },
      {
     sno: "6",
     Holiday: "Gandhi Jayanti*",
     Date: "2nd October",
     Day: "Wednesday"
      },
       {
      sno: "7",
      Holiday: "Ayudha Pooja/ Dussehra",
      Date: "11th October",
      Day: "Friday"
       },
        {
       sno: "8",
       Holiday: "Diwali",
       Date: "31st October",
       Day: "Thursday"
        },
         {
        sno: "9",
        Holiday: "Diwali/ Kannada Rajyothsava",
        Date: "1st November",
        Day: "Friday"
         },
          {
         sno: "10",
         Holiday: "Christmas Day",
         Date: "25th December",
         Day: "Wednesday"
          }

 ]


  return (
   <div className="leave-details-container">

   <p className="leave-year">Holidays 2024</p>

   <table className="leave-table">
   <thead>
   <tr>
   <th>Sno</th>
   <th>Holiday</th>
   <th>Date</th>
   <th>Day</th>
   </tr>
   </thead>
   <tbody>
   {
   data.map((val,i)=>(
   <tr key={i}>
   <td>{val.sno}</td>
   <td>{val.Holiday}</td>
   <td>{val.Date}</td>
   <td>{val.Day}</td>
   </tr>
   ))
   }

   </tbody>
   </table>

   <p><u>*Mandatory/ National Holiday</u></p>

       </div>
  );
}



export default LeaveDetails;
